import { useEffect, useState } from 'react';
import {
  Button,
  ExtraHuge,
  Large,
  Standard,
  View,
  Link,
  Image,
  CheckList,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import styled from '@/core/styled';
import Leap from '@/Assets/leap.svg';
import { trackEvent } from '@/utils/analytics/events';
import { getBHPlanReviewByPlan, parseTextInBullets } from '@/utils/reviewPlan';
import { CoveredDetailsModal } from './CoveredDetailsModal';
import { useEstimatedCost } from '@/hooks';
import { CoveredProps } from './types';
import { useHomePageState } from '@/Components/HomePage';

const Styled = {
  Heading: styled(ExtraHuge)(() => {
    return {
      marginBottom: 25,
    };
  }),
  View: styled(View)(() => {
    return {
      maxWidth: 430,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'left',
      paddingTop: 48,
    };
  }),
  Divider: styled(View)(({ theme: { colors } }) => {
    return {
      borderColor: colors.permaMystic,
      borderStyle: 'solid',
      borderTop: 1,
      borderWidth: 1,
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
    };
  }),
  LeapContainer: styled(View)({
    marginBottom: 25,
  }),
  MainContainer: styled(View)({
    textAlign: 'left',
    display: 'block',
  }),
  Button: styled(Button)({
    width: '100%',
    fontWeight: 'bold',
  }),
  DetailsLink: styled(Link)(({ theme: { colors } }) => {
    return {
      color: colors.permaBlueStoneNew,
      marginTop: 16,
      marginBottom: 16,
      fontSize: 14,
      fontWeight: 'bold',
      display: 'block',
    };
  }),
  EstimatedCost: styled(ExtraHuge)(({ theme: { colors } }) => {
    return {
      color: colors.permaBlueStoneNew,
      marginTop: 10,
      fontSize: 22,
    };
  }),
  Disclaimer: styled(Standard)(() => {
    return {
      marginTop: 16,
      fontWeight: 400,
      fontSize: 13,
    };
  }),
};

const Covered = ({ step, updateStep }: CoveredProps) => {
  useEffect(() => {
    trackEvent('Covered by Health Insurance Seen', {
      stepId: step.id,
      stepName: 'Covered by Health insurance',
      funnelName: 'QuickMatch',
      category: '',
    });
  }, [step.id]);

  const [detailsModalIsVisible, setDetailsModalIsVisible] = useState(false);
  const { perSession, perSessionMin, maxCost, specifyCopay } = useEstimatedCost();
  const { isVideoOnlyPlan } = useHomePageState();
  const { registrationAckp0Copay: registrationAckp0CopayActive } = useFlags();

  const showDetailsModal = () => setDetailsModalIsVisible(true);
  const hideDetailsModal = () => setDetailsModalIsVisible(false);

  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  const planReview = getBHPlanReviewByPlan(isVideoOnlyPlan);
  const bullets =
    parseTextInBullets({
      bullets: planReview.bullets,
      bulletsTranslationKeys: planReview.bulletsTranslationKeys,
      tQuickmatchCommon,
    }) || [];

  return (
    <>
      <Styled.View>
        <Styled.LeapContainer>
          <Image src={Leap} />
        </Styled.LeapContainer>
        <Styled.Heading>You’re covered by your health insurance!</Styled.Heading>
        <Styled.MainContainer>
          <CheckList items={bullets} />
          <Styled.Disclaimer>{planReview.disclaimerText}</Styled.Disclaimer>
          <Styled.DetailsLink onClick={showDetailsModal}>More details</Styled.DetailsLink>
          <Styled.Divider />
          {(!registrationAckp0CopayActive || perSession > 0) && (
            <>
              <Styled.EstimatedCost>
                {perSessionMin !== undefined ? `$${perSessionMin}-` : ''}${perSession}
                {specifyCopay ? ' copay' : ''} per session*
              </Styled.EstimatedCost>
              <Large>Maximum cost of service: ${maxCost}/session</Large>
            </>
          )}
        </Styled.MainContainer>
        <Styled.Button
          dataQa="continueWithInsurance"
          onClick={() => {
            trackEvent('Covered by Health Insurance CTA', {
              stepId: step.id,
              stepName: 'Covered by Health insurance',
              funnelName: 'QuickMatch',
              category: '',
            });
            updateStep(step.id, {
              quickStatusAction: 'continueWithInsurance',
            });
          }}
        >
          Continue
        </Styled.Button>
      </Styled.View>
      <CoveredDetailsModal
        hideDetailsModal={hideDetailsModal}
        isVisible={detailsModalIsVisible}
        modalDetails={planReview.modalDetails}
      />
    </>
  );
};

export default Covered;
