import Screen from '@/directory/Screen';
import TherapistList from '@/directory/TherapistList';
import SearchBar from '@/directory/SearchBar';

const Directory = () => (
  <Screen>
    <SearchBar />
    <TherapistList />
  </Screen>
);

export default Directory;
