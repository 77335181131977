/**
 * Asks the user the bare minimum questions in order to determine their insurance coverage status.
 *
 * @todo - Field error states
 */
import { useEffect } from 'react';
import compose from 'lodash/fp/compose';
import {
  Button,
  RHFInput,
  RHFDateInput,
  RHFSelect,
  useEmotionTheme,
  ExtraHuge,
  Small,
  Large,
  Link,
  View,
} from '@talkspace/react-toolkit';
import { FormProvider, useWatch, useFormContext } from 'react-hook-form';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils';
import { countries } from '@/Helpers/locales';
import styled from '@/core/styled';
import { useContinue, useFormSaver, useQuickEligibilityForm } from './hooks';
import { useEligibilityService, EligibilityServiceFormData } from '@/hooks/useEligibilityService';
import { stateOptions } from './util';
import StatusModal from './StatusModal';
import { QuickEligibilityProps } from './types';
import countriesHelper from '@/Helpers/countriesHelper';

const AA_TOOLTIP_STYLE = {
  width: 23,
  height: 34,
  alignItems: 'flex-end',
  display: 'flex',
  marginBottom: 0,
  paddingBottom: 6,
};

const Styled = {
  Heading: styled(ExtraHuge)(() => {
    return {
      marginBottom: 10,
    };
  }),
  Subheading: styled(Large)(({ theme: { colors } }) => {
    return {
      fontSize: 16,
      color: colors.darkGray,
      marginBottom: 32,
    };
  }),
  View: styled(View)(() => {
    return {
      maxWidth: 430,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    };
  }),
  Form: styled.form(() => {
    return {
      maxWidth: 335,
      width: '100%',
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'stretch',
      textAlign: 'center',
    };
  }),
  FieldWrapper: styled(View)({
    width: '100%',
    maxWidth: 165,
    '@media(max-width: 350px)': {
      maxWidth: 335,
    },
  }),
  Row: styled(View)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 335,
    '@media(max-width: 350px)': {
      display: 'inline-block',
    },
  }),
  Footer: styled(View)({
    maxWidth: 335,
    marginTop: 25,
  }),
  Error: styled(View)(({ theme: { colors } }) => {
    return {
      display: 'block',
      maxWidth: 335,
      marginTop: 12,
      padding: 20,
      backgroundColor: colors.permaProvincialPink,
      borderRadius: 9,
      color: colors.permaOuterSpaceNew,
      textAlign: 'left',
      '> a': {
        color: colors.permaErrorRed,
      },
    };
  }),
};

const QuickEligibility = ({
  updateStep,
  step,
  insurancePayer,
  setReferralSource,
  referralSource,
}: QuickEligibilityProps) => {
  const { colors } = useEmotionTheme();
  useFormSaver();

  const { result, done, errorType, canRetry, attempts, isSubmitting, submit } =
    useEligibilityService({
      maxAttempts: 3,
    });

  const formContext = useFormContext();
  const { handleSubmit, setValue } = formContext;
  const data = useWatch() as any as EligibilityServiceFormData;

  useContinue({
    result,
    data,
    done,
    errorType,
    updateStep,
    step,
    insurancePayer,
  });

  const state = useWatch({
    name: 'state',
  });

  useEffect(() => {
    const { referralSource: sessionReferralSource } = getSessionStorageValuesFromJson(
      SESSION_STORAGE_MEMBER_DETAILS_KEY,
      ['referralSource']
    );
    if (sessionReferralSource?.value) {
      setReferralSource(sessionReferralSource.value);
    }
  }, [setReferralSource]);

  const isUnder13 = formContext.formState.errors.dob?.type === 'under13';
  const statusModalIsVisible = (isSubmitting || (attempts > 0 && canRetry)) && !done;
  const shouldRetry = attempts > 0 && !isSubmitting && canRetry;
  const isManualSignUpFlow = referralSource === 'manualSignUp';

  if (result) {
    return null;
  }

  const handleOnSubmit = (formData: EligibilityServiceFormData) => {
    const parsedFormData = JSON.parse(JSON.stringify(formData));

    const { state: clientState, country } = countriesHelper.getStateAndCountryOverrides(
      parsedFormData.country,
      parsedFormData.state
    );

    parsedFormData.country = country;
    parsedFormData.state = clientState;

    return submit(parsedFormData);
  };

  return (
    <Styled.View>
      <StatusModal
        visible={statusModalIsVisible}
        isCheckingCoverage={isSubmitting}
        showRetryButton={shouldRetry}
        onHandleRetry={() => submit(data)}
      />
      <Styled.Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Styled.Heading>Let’s verify your insurance information</Styled.Heading>
        <Styled.Subheading>We’ll use this to determine your session cost</Styled.Subheading>
        <RHFInput
          fieldName="memberID"
          label="Member / Subscriber ID"
          tooltip="Located on your Health Plan ID card. Type the full ID, including letters and numbers."
          isRequired
          data-qa="member-id-input"
          onChange={(e) => {
            const newValue = e.target.value.replace(/\s/gi, '').toLowerCase();
            if (newValue !== e.target.value) {
              setValue('memberID', newValue);
            }
          }}
          labelStyle={{ alignSelf: 'flex-end' }}
          tooltipStyle={AA_TOOLTIP_STYLE}
          isDisabled={isManualSignUpFlow && !!data.memberID}
        />
        <Styled.Row>
          <Styled.FieldWrapper>
            <RHFInput
              fieldName="firstName"
              label="Patient first name"
              placeholder="First name"
              isRequired
              data-qa="first-name-input"
              isDisabled={isManualSignUpFlow && !!data.firstName}
            />
          </Styled.FieldWrapper>
          <Styled.FieldWrapper>
            <RHFInput
              fieldName="lastName"
              label="Patient last name"
              placeholder="Last name"
              isRequired
              data-qa="last-name-input"
              isDisabled={isManualSignUpFlow && !!data.lastName}
            />
          </Styled.FieldWrapper>
        </Styled.Row>
        <RHFDateInput
          fieldName="dob"
          label="Date of birth"
          isRequired
          data-qa="dob-input"
          containerStyle={{ marginBottom: 0 }}
          isDisabled={isManualSignUpFlow && !!data.dob}
        />
        <RHFSelect
          fieldName="state"
          label="Location"
          options={stateOptions}
          allowMobileSearch
          registerOptionValue
          placeholder="Select your location"
          tooltip="Select the location associated with your insurance."
          labelStyle={{ paddingBottom: 4 }}
          labelContainerStyle={{ alignItems: 'flex-end', marginBottom: 0 }}
          tooltipProps={{
            messageStyle: { marginRight: 10 },
            buttonStyle: AA_TOOLTIP_STYLE,
          }}
          isDisabled={isManualSignUpFlow && !!data.state}
        />
        {state === 'other' && (
          <RHFSelect
            fieldName="country"
            label="Country"
            options={countries}
            allowMobileSearch
            registerOptionValue
            placeholder="Select your country"
          />
        )}
        {isUnder13 && (
          <Styled.Error>
            Talkspace cannot provide service to individuals under the age of 13 at this time. If you
            or anyone you know are in a crisis or may be in danger, please use the following{' '}
            <Link href="https://google.com">resources</Link> to get immediate help.
          </Styled.Error>
        )}
        <Button
          text="Continue"
          roundedFocusStyle
          disabled={done}
          isLoading={isSubmitting}
          primaryColor={colors.green}
          dataQa="shortEligibilityCTA"
          style={{ alignSelf: 'center', width: '100%', fontWeight: 'bold' }}
        />
        <Styled.Footer>
          <Small>
            Talkspace is committed to protecting your privacy and follows HIPAA, state and federal
            laws.
          </Small>
        </Styled.Footer>
      </Styled.Form>
    </Styled.View>
  );
};

export default compose((Component) => (props) => {
  const methods = useQuickEligibilityForm();
  return (
    <FormProvider {...methods}>
      <Component {...props} />
    </FormProvider>
  );
})(QuickEligibility) as typeof QuickEligibility;
