import {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SearchParams } from '@/directory/types';
import { verifySearchParams } from '@/directory/validator';

type Mode = 'dev' | 'standard';

interface DirectoryState {
  searchParams: SearchParams;
  isSearchValid: boolean;
  mode: Mode;
}

export interface DirectoryActions {
  setSearchParams: (newState: Partial<SearchParams>) => void;
  clearSearchParams: () => void;
}

const DirectoryStateContext = createContext<DirectoryState | undefined>(undefined);
const DirectoryActionsContext = createContext<DirectoryActions | undefined>(undefined);

const defaultSearchParams: SearchParams = {
  name: '',
  gender: null,
  age: 0,
  state: null,
  payer: null,
  country: 'US',
  specialty: null,
  communicationPreferences: null,
  conversationalDynamics: null,
  whyAreYouHere: '',
  aiPrompt: '',
};

interface Props {
  mode: Mode;
}

export const DirectoryProvider: FunctionComponent<Props> = ({ mode, children }) => {
  const [searchParams, _setSearchParams] = useState<SearchParams>(defaultSearchParams);

  const [isSearchValid, setIsSearchValid] = useState(false);

  useEffect(() => {
    const valid = verifySearchParams(searchParams);
    if (valid !== isSearchValid) {
      setIsSearchValid(valid);
    }
  }, [isSearchValid, searchParams]);

  const setSearchParams = (newState: Partial<SearchParams>) => {
    _setSearchParams((currentState) => {
      return { ...currentState, ...newState };
    });
  };

  const clearSearchParams = () => {
    setSearchParams(defaultSearchParams);
  };

  const actions: DirectoryActions = {
    setSearchParams: useCallback(setSearchParams, []),
    clearSearchParams: useCallback(clearSearchParams, []),
  };

  return (
    <DirectoryStateContext.Provider
      value={{
        mode,
        searchParams,
        isSearchValid,
      }}
    >
      <DirectoryActionsContext.Provider value={actions}>
        {children}
      </DirectoryActionsContext.Provider>
    </DirectoryStateContext.Provider>
  );
};

export const useDirectoryState = () => {
  const context = useContext(DirectoryStateContext);
  if (context === undefined) {
    throw new Error('useDirectoryState must be used within a DirectoryProvider');
  }
  return context;
};

export const useDirectoryActions = () => {
  const context = useContext(DirectoryActionsContext);
  if (context === undefined) {
    throw new Error('useDirectoryActions must be used within a DirectoryProvider');
  }
  return context;
};
