import { useQuery, UseQueryResult } from 'react-query';

import apiHelper from '@/core/api/apiHelper';
import { SearchParams, DirectoryProvider } from '@/directory/types';
import { formatSearchParams } from '@/directory/utils';
import { therapistsQueryKey } from '@/directory/queryKeys';

const fetchTherapists = (params: SearchParams) => async (): Promise<DirectoryProvider[]> => {
  const apiParams = formatSearchParams(params);

  const nonEmptyPayload = {};

  Object.keys(apiParams).forEach((key) => {
    if (apiParams[key]) {
      nonEmptyPayload[key] = apiParams[key];
    }
  });

  const response = await fetch(`${apiHelper().publicEndpoint}public/v3/therapists`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(nonEmptyPayload),
  });

  const {
    data: { therapists },
  } = await response.json();

  return therapists;
};

const useQueryTherapists = (
  params: SearchParams,
  enabled: boolean
): UseQueryResult<DirectoryProvider[], Error> =>
  useQuery<DirectoryProvider[], Error>({
    queryKey: therapistsQueryKey(params),
    queryFn: fetchTherapists(params),
    cacheTime: 10 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled,
  });

export default useQueryTherapists;
