import { routePromise } from 'ts-frontend/helpers';
import { ConditionalResponse, Currency } from 'ts-frontend/types';
import { createCookie } from 'ts-frontend/utils';
import { createRoom, registerUserWithPayment, registerUserWithPaymentCognito } from './apiService';
import {
  trackEvent,
  VWO,
  identify,
  setPeopleLogin,
  getPlatform,
  trackGTMEvent,
} from '../utils/analytics/events';
import { HomePageState, RoomType } from '../Components/HomePage/types';
import ReactFrameService from '../utils/reactFrame/ReactFrameService';
import configs from '../utils/configs';
import apiWrapper from '../core/api/apiWrapper';
import { getQMFunnelName } from './flowsHelper';

export const INSURANCE_INFO_ERROR_CODE = 'insuranceInfoError';

export enum RegistrationErrors {
  generalError = 'Oops, we encountered an error. Double check all your information and try again.',
  duplicateInsuranceUser = 'Auth code is invalid. Double check all your information and try again.',
  duplicateInsuranceDetails = 'Oops, one or more of your insurance information is invalid. Double check all your information and try again.',
}

interface RegistrationResponse<TSuccess, TFailure> {
  data: TSuccess | null;
  errors: TFailure | null;
}

export interface RegisterWithCreditCardParams {
  payload: {
    email: string;
    planId: number;
    promoCode?: string;
    tokenizedCreditCard: string;
    // Data from QM
    appointment: HomePageState['appointment'];
    timezone: string;
    therapistId: HomePageState['therapistId'];
    updateCoverageRoomID: HomePageState['updateCoverageRoomID'];
    attribution: {
      referrerUrl: string;
      registrationUrl: string;
    };
    quickmatchResponses: HomePageState['responses'];
    presentingProblems: HomePageState['clientMatchPresentingProblems'];
    funnelVariation: HomePageState['funnelVariation'];
    clientDateOfBirth: HomePageState['clientDateOfBirth'];
    clientState: HomePageState['clientState'];
    clientCountry: HomePageState['clientCountry'];
    insuranceCode: HomePageState['insuranceCode'] | null;
    trizettoRequest: HomePageState['trizettoRequest'];
    roomType: RoomType | undefined;
    gender?: number;
    isPendingMatch?: boolean;
    flowID: number;
    offerID: number;
  };
  analytics: {
    discountPercent: number;
    promoValue: number;
    promoCode?: string;
    plan: {
      id: number;
      name: string;
      billingFrequency: number;
      price: number;
    };
    flowId: number;
    offerId: number;
    currency: Currency;
    requestedOfferID: number;
  };
}

export function getParsedUserIDFromQMSession(): number | undefined {
  const stringUserID = apiWrapper.currentUserID();
  if (!stringUserID) return undefined;
  // tsUser_ is the prefix used for Eligibility Widget
  const userID = Number(stringUserID.replace('tsUser_', ''));
  // Fallback for NaN
  return isNaN(userID) ? undefined : userID;
}

export async function trackPurchaseEvents(
  analytics: RegisterWithCreditCardParams['analytics'],
  roomID: number,
  roomType?: RoomType,
  userID?: number
) {
  if (userID) {
    // GTM QM Payment Event
    trackGTMEvent('qmPaymentComplete', {
      promoCode: analytics?.promoCode || '',
      result: 'success',
      platform: getPlatform(),
      tspl: roomType === 'psychiatryRoom' ? 2 : 1,
    });
    VWO.trackQMPurchaseGoal();

    identify(userID);
    setPeopleLogin(userID);
  }

  VWO.trackPurchaseGoal();
  VWO.trackRegisterGoal();
}

interface RegisterWithPaymentResponse {
  authToken: string;
  userID: number;
  updateCredentialsJWTToken: string;
}

interface RegisterWithPaymentCreateRoomFlowResponse {
  roomID: number;
  oldRoomHasSessions: boolean;
  sessionsCanceled: boolean;
  sessionsTransferred: boolean;
  therapistID: number;
}

interface RegisterWithPaymentErrors {
  serverError: string | null;
  stripeErrorMessage: string | null;
  errorsCodesExists: boolean;
}

export async function registerWithPayment<TBoolean extends boolean>(
  params: RegisterWithCreditCardParams,
  isCreateRoomFlow: TBoolean,
  isReactivationFlow: boolean,
  isUpdateCoverageFlow: boolean,
  isMBHIneligibilityFlow: boolean,
  cognitoActive: boolean,
  isTestUser: boolean
): Promise<
  ConditionalResponse<
    TBoolean,
    RegistrationResponse<RegisterWithPaymentResponse, RegisterWithPaymentErrors>,
    RegistrationResponse<RegisterWithPaymentCreateRoomFlowResponse, RegisterWithPaymentErrors>
  >
> {
  try {
    const isLoggedInUser =
      isCreateRoomFlow || isReactivationFlow || isUpdateCoverageFlow || isMBHIneligibilityFlow;
    if (isLoggedInUser) {
      const createRoomResponse = await createRoom(params.payload);
      const { roomID } = createRoomResponse;
      trackPurchaseEvents(params.analytics, roomID, params.payload.roomType);
      return { data: createRoomResponse, errors: null } as ConditionalResponse<
        TBoolean,
        RegistrationResponse<RegisterWithPaymentResponse, RegisterWithPaymentErrors>,
        RegistrationResponse<RegisterWithPaymentCreateRoomFlowResponse, RegisterWithPaymentErrors>
      >;
    }
    const {
      user: { id: userID, updateCredentialsJWTToken },
      room: { id: roomID },
      authToken,
    } = cognitoActive
      ? await registerUserWithPaymentCognito(params.payload)
      : await registerUserWithPayment(params.payload);

    sessionStorage.clear();

    trackPurchaseEvents(params.analytics, roomID, params.payload.roomType, userID);

    return {
      data: {
        authToken,
        updateCredentialsJWTToken,
        userID,
      },
      errors: null,
    } as ConditionalResponse<
      TBoolean,
      RegistrationResponse<RegisterWithPaymentResponse, RegisterWithPaymentErrors>,
      RegistrationResponse<RegisterWithPaymentCreateRoomFlowResponse, RegisterWithPaymentErrors>
    >;
  } catch (error) {
    const errors =
      error && error.response && error.response.data && error.response.data.errors
        ? error.response.data.errors
        : ''; // Maybe this should be an empty array. Copied from RegisterWithCreditCard.js:369
    const {
      promoCode,
      plan,
      flowId,
      offerId,
      requestedOfferID,
      discountPercent,
      promoValue,
      currency,
    } = params.analytics;

    const stringUserID = apiWrapper.currentUserID();
    const tsUserID = getParsedUserIDFromQMSession();

    await trackEvent('Failed to Purchase', {
      'Subscription Plan': plan.name,
      'Subscription Value': plan.price,
      'Billing Frequency': plan.billingFrequency,
      'Promo Code': promoCode,
      'Funnel Name': getQMFunnelName({
        isCreateRoomFlow,
        isReactivationFlow,
        isUpdateCoverageFlow,
        isMBHIneligibilityFlow,
      }),
      isFirstPurchase: true,
      isTestUser,
      Successful: false,
      'Error Codes': errors,
      'Flow ID': flowId,
      offerId,
      requestedOfferID,
      type: 'B2C',
      'Pending Match': !!params.payload.isPendingMatch,
      'Discount %': discountPercent,
      planId: plan.id,
      'Promo Value': promoValue,
      'User ID': tsUserID || stringUserID,
      Currency: currency,
    });

    if (!Array.isArray(errors) || !errors.length || errors[0].code === 'paramsError') {
      return {
        data: null,
        errors: {
          serverError: RegistrationErrors.generalError,
          stripeErrorMessage: null,
          errorsCodesExists: false,
        },
      } as ConditionalResponse<
        TBoolean,
        RegistrationResponse<RegisterWithPaymentResponse, RegisterWithPaymentErrors>,
        RegistrationResponse<RegisterWithPaymentCreateRoomFlowResponse, RegisterWithPaymentErrors>
      >;
    }

    const errorResponse: ConditionalResponse<
      TBoolean,
      RegistrationResponse<RegisterWithPaymentResponse, RegisterWithPaymentErrors>,
      RegistrationResponse<RegisterWithPaymentCreateRoomFlowResponse, RegisterWithPaymentErrors>
    >['errors'] = {
      serverError: null,
      stripeErrorMessage: null,
      errorsCodesExists: false,
    };
    // parse errors and set state
    errors.forEach(({ code, message } = {}) => {
      if (code === 'serverError') {
        errorResponse.errorsCodesExists = true;
        errorResponse.serverError = message;
      }

      if (code === 'paymentError') {
        errorResponse.errorsCodesExists = true;
        errorResponse.stripeErrorMessage = message || 'Payment error';
      }
    });
    return { errors: errorResponse, data: null } as ConditionalResponse<
      TBoolean,
      RegistrationResponse<RegisterWithPaymentResponse, RegisterWithPaymentErrors>,
      RegistrationResponse<RegisterWithPaymentCreateRoomFlowResponse, RegisterWithPaymentErrors>
    >;
  }
}

const normalRedirect = (url: string) => {
  window.onbeforeunload = null;
  window.location.replace(url);
};

export function redirectToRoom(roomURL: string): void {
  if (ReactFrameService.instance().isInFrame()) {
    const hashParams = new URLSearchParams(roomURL.split('#')[1] || '');
    const tlt = hashParams.get('tlt');
    const email = hashParams.get('email');

    if (email) {
      return ReactFrameService.instance().closePopup({
        navigateTo: 'emailVerification',
        metadata: {
          email,
        },
      });
    }

    return ReactFrameService.instance().closePopup({
      navigateTo: 'authenticatedDashboard',
      metadata: {
        tlt: tlt || 'InvalidTLT',
      },
    });
  }
  return normalRedirect(roomURL);
}

export function redirectToAccountActivation({
  email,
  phone,
  userID,
  updateCredentialsJWTToken,
  authToken,
  isNoMatches,
  collectReferralSourceOnSignUp,
  smsConsent,
}: {
  email?: string;
  phone?: string;
  userID: number;
  updateCredentialsJWTToken: string;
  authToken?: string;
  isNoMatches: boolean;
  collectReferralSourceOnSignUp?: boolean;
  smsConsent: boolean;
}): void {
  const urlParams = new URLSearchParams({
    token: updateCredentialsJWTToken,
    isNoMatches: `${isNoMatches}`,
  });
  if (authToken) urlParams.set('secondaryToken', authToken);
  if (email) urlParams.set('email', encodeURIComponent(email));
  if (phone) urlParams.set('phone', encodeURIComponent(phone));
  if (smsConsent) urlParams.set('smsConsent', 'true');
  if (collectReferralSourceOnSignUp) urlParams.set('collectReferralSourceOnSignUp', 'true');
  const URL = `/users/account-activation/${userID}#${urlParams}`;

  return normalRedirect(URL);
}

export function redirectToClientWebSignup() {
  const REGISTER_COOKIE = '_tsRegisterFlow';
  const BOUGHT_FROM_PAY_FIRST_PAGE = 30;

  const flowData = {
    roomType: 'privateRoom',
    funnelVariation: 'qm_ct_exit',
    registrationUrl: window.location.href,
    referrerUrl: document.referrer,
    boughtFrom: BOUGHT_FROM_PAY_FIRST_PAGE,
  };

  const dataToPost = {
    flowData,
    qmSessionID: apiWrapper.currentUserID(),
  };
  dataToPost.flowData.boughtFrom = BOUGHT_FROM_PAY_FIRST_PAGE;

  const cookieLifetime = 7 * 24 * 60 * 60; // 7 days

  createCookie(REGISTER_COOKIE, JSON.stringify(dataToPost), cookieLifetime);

  return routePromise(`${configs.endpoints.clientWebEndpoint}/signup`);
}
