/* eslint-disable import/prefer-default-export */

import { SearchParams } from '@/directory/types';

export const verifySearchParams = ({ country, state, specialty }: SearchParams): boolean => {
  if (!country) {
    return false;
  }

  if (!state) {
    return false;
  }

  if (!specialty) {
    return false;
  }

  return true;
};
