// eslint-disable import/prefer-default-export
import { AxiosError } from 'axios';
import { History } from 'history';
import moment from 'moment-timezone';
import { FieldError } from 'react-hook-form';
import { InsuranceEligibilityPayer, ServiceType } from 'ts-frontend/types';
import { EligibilityError } from '@/Components/OneFormEligibility/OneFormEligibilityError';
import apiWrapper from '../../core/api/apiWrapper';
import {
  DEFAULT_FLOWS,
  FLOW_112_B2B_BH_TRIZETTO_BEACON,
  FLOW_143_B2B_BH_TRIZETTO_DISTRICT9,
  FLOW_149_B2B_BH_TRIZETTO_TRICARE_EAST,
  FLOW_62_B2B_EAP_OPTUM,
  FLOW_78_B2B_EAP_CIGNA,
} from '../../Flows';
import { EligibilityType, FlowConfig, OneFormEligibilityStep, UpdateStep } from '../../Flows/types';
import apiHelper, { apiHelperV4 } from '../../Helpers/apiHelper';
import { useRecoveredSessionState } from '../../hooks/recoveredSessionContext';
import getParamByName from '../../utils/queryString';
import { OneFormEligibilityFieldNames, OneFormEligibilityFields } from './types';
import { AccountType } from '../HomePage/types';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { activateVwoOonExperiment, setVWOInsuranceProvider } from '@/utils/vwoHelper';
import { getAccessCodeByZipCode, putAnonymousUserDetails } from '../../Helpers/apiService';

const TIMEOUT_TRIZETTO_VERIFICATION = 15000;
const BEACON_TIMEOUT_TRIZETTO_VERIFICATION = 60000;

// TODO: Remove after this feature passes QA
const warnIfEmpty = (
  value: string | undefined,
  fieldName: string,
  functionName: string
): string => {
  if (!value) {
    // This function will be removed
    // eslint-disable-next-line no-console
    console.warn('OneFormEligibility - Warning: The given field was empty', {
      fieldName,
      functionName,
    });
    return '';
  }
  return value;
};

interface KeywordRequestParams {
  email: string | undefined;
  flowId: number;
  keyword?: string | null;
  metadata: KeywordRequestMetadata;
  accessCode?: string;
}

interface ZipCodeRequestParams {
  email: string | undefined;
  flowId: number;
  zipCode: string | null;
  metadata: KeywordRequestMetadata;
  accessCode?: string;
}

interface KeywordRequestMetadata {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phone?: string;
  employeeId?: string;
  employeeRelationship?: string;
  heardAbout?: string;
  memberId?: string;
  groupId?: string;
  address?: string;
  addressStreet?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  numberOfSessions?: string;
  voucher?: string;
  authorizationCode?: string;
  authorizationCodeExpiration?: string;
  organizationCode?: string;
  employeeFirstName?: string;
  employeeLastName?: string;
  employeeSameAddressCheckbox?: boolean;
  employeeAddressLine1?: string;
  employeeAddressLine2?: string;
  employeeCity?: string;
  employeeState?: string;
  employeeZipcode?: string;
  employeeCountry?: string;
  attendedSchool?: string;
  socialSecurity?: string;
  secondaryMemberID?: string;
  secondaryPayerID?: string;
}

interface TrizettoParams {
  accessCode?: string;
  dateOfBirth: string;
  firstName: string;
  flowId?: number;
  lastName: string;
  memberID: string;
  sendEmail?: boolean;
  service?: ServiceType | null;
  getVoucherParams?: KeywordRequestParams;
  userEmail?: string;
  state?: string;
  gediPayerID?: string;
  partnerID: number | null;
}

interface OneFormEligibilityDispatcherOverrides {
  dispatcherOrgName: string | null;
  dispatcherKeyword: string | null;
  dispatcherOrgEmail: string | null;
}

type KeywordRequestResponse = {
  accessCode: string;
  accessCodeType: AccessCodeType | undefined;
  allowedModalities?: Array<AllowedModality>;
  totalSessions: number;
  accountType: AccountType | null;
  /** Only set when timeout occurs */
  qmFlowID?: number;
  /** Only set when timeout occurs */
  redirectFrom?: number;
};

interface TrizettoError extends Error {
  response?: {
    data?: {
      name?: string;
      message?: string;
      error?: {
        error?: string;
      };
    };
  };
}

export const useDispatcherDefaultEligibilityParams = (
  currentFlowID: number
): OneFormEligibilityDispatcherOverrides => {
  const result: OneFormEligibilityDispatcherOverrides = {
    dispatcherOrgName: null,
    dispatcherKeyword: null,
    dispatcherOrgEmail: null,
  };
  const { dispatcherState } = useRecoveredSessionState();
  if (dispatcherState && dispatcherState.goToFlowID === currentFlowID) {
    if (dispatcherState.organizationName)
      result.dispatcherOrgName = dispatcherState.organizationName;
    if (dispatcherState.saveMetadataKeyword)
      result.dispatcherKeyword = dispatcherState.saveMetadataKeyword;
    if (dispatcherState.organizationEmail)
      result.dispatcherOrgEmail = dispatcherState.organizationEmail;
  }

  return result;
};

/**
 * Normalize keyword by trimming prefixed alphanumeric characters and lower-casing)
 * @param {keyword} keyword
 */
const formatKeyword = (keyword: string): string =>
  keyword.replace(/[^a-z0-9]/gim, '').toLowerCase();

/**
 * removes leading and trailing white spaces from memberId and lower-cases
 * @param {memberId} memberId
 */
const trimAndLowerCaseMemberId = (memberId?: string): string | undefined =>
  memberId ? memberId.trim().toLowerCase() : undefined;

const submitEmailToBraze = (marketingConsent: boolean | undefined, email: string) => {
  if (email) {
    return putAnonymousUserDetails({
      email,
      ignoreIfNotNull: true,
      // Do not send consent if the user was not asked
      ...(marketingConsent !== undefined ? { consent: !!marketingConsent } : {}),
    });
  }
  return undefined;
};

const handleEligibilityVoucherError = (updateStep: UpdateStep, step: OneFormEligibilityStep) =>
  updateStep(step.buttonTarget, {
    invalidVoucher: true,
  });

const isTrizettoServiceError = (err: TrizettoError) =>
  (err.message && (err.message.match(/timeout of \w*ms exceeded/) || err.message.match(/408/))) ||
  (err.response?.data as string) === 'Trizetto internal error' ||
  err.response?.data?.name === 'PayerTimeout' ||
  err.response?.data?.name === 'UnableToRespond';

const getAccessCodeTypeFromSearch = (): AccessCodeType | null =>
  getParamByName('accessCodeType') as AccessCodeType;

export const getAccessCodeFromSearch = (): string | null => {
  const accessCodeType = getAccessCodeTypeFromSearch();
  if (!accessCodeType) return null;
  return getParamByName(accessCodeType);
};

// manual, file-based, and trizetto flows create the keyword from the selected or default service type
const getKeywordFromServiceType = (
  serviceType?: string,
  flowConfig?: FlowConfig,
  isTrizettoTimeout?: boolean
): string => {
  const serviceKeywords = isTrizettoTimeout
    ? flowConfig?.serviceKeywordsManual
    : flowConfig?.serviceKeywords;
  if (!serviceKeywords) return '';
  if ((!serviceType || !serviceKeywords[serviceType]) && serviceKeywords.psychotherapy) {
    return serviceKeywords.psychotherapy;
  }
  return serviceType ? serviceKeywords[serviceType] : '';
};

const getKeyword = (
  flowId: number,
  oneFormData: OneFormEligibilityFields,
  flowConfig?: FlowConfig
): string | null => {
  let keyword: string | null = null;
  if (flowId === FLOW_78_B2B_EAP_CIGNA) {
    keyword = `cignawellbeingprogram${warnIfEmpty(
      oneFormData.numberOfSessions?.value,
      'numberOfSessions.value',
      'getKeyword'
    )}`;
  } else if (flowId === FLOW_62_B2B_EAP_OPTUM) {
    keyword = `optumwellbeingprogram${warnIfEmpty(
      oneFormData.numberOfSessions?.value,
      'numberOfSessions.value',
      'getKeyword'
    )}`;
  } else if (
    flowConfig &&
    (flowConfig.eligibilityType === EligibilityType.manual ||
      flowConfig.eligibilityType === EligibilityType.trizetto ||
      flowConfig.eligibilityType === EligibilityType.fileBased) &&
    oneFormData.serviceType?.value
  ) {
    keyword = getKeywordFromServiceType(oneFormData.serviceType.value, flowConfig);
  } else {
    keyword = oneFormData.organizationName || null;
    if (!keyword) return null;
  }
  return formatKeyword(keyword);
};

const makeKeywordRequest = async (
  params: KeywordRequestParams,
  dispatcherKeyword: string | null
): Promise<KeywordRequestResponse> => {
  if (getAccessCodeFromSearch() && getAccessCodeTypeFromSearch() !== 'authCode') {
    const keyword = dispatcherKeyword || params.keyword;
    const sanitizedKeyword = keyword?.replace(/[^a-z0-9]/gim, '').toLowerCase();
    const accessCode = getAccessCodeFromSearch();
    const { allowedModalities, totalSessions, accountType } = await apiWrapper.post(
      `${apiHelper()}accessCodeBy/save-keyword-metadata`,
      {
        email: params.email,
        metadata: params.metadata,
        keyword: sanitizedKeyword,
        accessCode,
      }
    );
    return {
      accessCode: accessCode || getAccessCodeFromSearch() || '',
      accessCodeType: getAccessCodeTypeFromSearch() || undefined,
      allowedModalities,
      totalSessions,
      accountType,
    };
  }
  return apiWrapper.post(`${apiHelperV4()}accessCodeBy/keywordMetadata`, params);
};

const checkPartnerEligibility = (
  partnerID: number,
  memberID: string,
  dateOfBirth: string
): Promise<{
  isEligible: boolean;
  errorMessage: string;
}> =>
  apiWrapper.post(
    `${apiHelper()}partners/${partnerID}/verify-member`,
    { memberID, dateOfBirth },
    { timeout: TIMEOUT_TRIZETTO_VERIFICATION }
  );

const checkTrizettoEligibilityV2 = (
  trizettoParams: TrizettoParams,
  timeoutInMilliseconds?: number
) =>
  apiWrapper.post(`${apiHelperV4()}eligibility/verify-member`, trizettoParams, {
    timeout: timeoutInMilliseconds,
  });

const buildFlowPushUrl = ({
  accessCode,
  accessCodeType,
  flowId,
  flowConfig,
  eligibilityWidgetHasRoomsParam,
  isCreateRoomFlow,
  isReactivationFlow,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
  formattedDateOfBirth,
  serviceType,
  allowedModalities,
  totalSessions,
  accountType,
  manualFlowID,
}: {
  accessCode: string;
  accessCodeType: AccessCodeType;
  flowId: number;
  flowConfig: FlowConfig | undefined;
  eligibilityWidgetHasRoomsParam?: boolean;
  isCreateRoomFlow?: boolean;
  isReactivationFlow?: boolean;
  isUpdateCoverageFlow?: boolean;
  isMBHIneligibilityFlow?: boolean;
  formattedDateOfBirth?: string;
  serviceType?: ServiceType | null;
  allowedModalities?: Array<AllowedModality>;
  totalSessions: number;
  accountType: AccountType | null;
  manualFlowID: number | undefined;
}): string => {
  const urlSearchParams = new URLSearchParams();
  // Sent by Aetna, BH, EligibilityFile flows (if isEligible), serviceType excluded from flows with eligibilityType.organization and eligibilityType.organizationWithAddress
  urlSearchParams.set('redirectFrom', String(flowId));
  urlSearchParams.set(accessCodeType, accessCode);
  if (
    serviceType &&
    flowConfig &&
    (flowConfig.eligibilityType === EligibilityType.manual ||
      flowConfig.eligibilityType === EligibilityType.trizetto ||
      flowConfig.eligibilityType === EligibilityType.fileBased)
  )
    urlSearchParams.set('serviceType', serviceType);
  if (formattedDateOfBirth) urlSearchParams.set('clientAge', formattedDateOfBirth);
  // Aetna specific
  if (isCreateRoomFlow) urlSearchParams.set('source', 'eligibility');
  if (isReactivationFlow) urlSearchParams.set('source', 'reactivation');
  if (isUpdateCoverageFlow) urlSearchParams.set('source', 'update-coverage');
  if (isMBHIneligibilityFlow) urlSearchParams.set('source', 'mbh-ineligibility');
  if (eligibilityWidgetHasRoomsParam) urlSearchParams.set('eligibilityWidgetHasRooms', 'true');
  if (allowedModalities) {
    urlSearchParams.set('allowedModalities', 'true');
    allowedModalities.forEach((allowedModality) => {
      urlSearchParams.set(
        allowedModality.name,
        allowedModality.isDefault ? 'isDefault' : 'isNotDefault'
      );
    });
  }
  if (totalSessions) {
    urlSearchParams.set('totalSessions', totalSessions.toString());
  }
  if (accountType) {
    urlSearchParams.set('accountType', accountType);
  }
  if (manualFlowID) {
    urlSearchParams.set('manualFlowID', manualFlowID.toString());
  }

  // Preserve HomePage states by redirecting to
  // specific flows, aligning with `src/routes.js`
  if (accessCodeType === 'qmPartnerCode') {
    return `/flow/${DEFAULT_FLOWS.B2B}/step/1/?${urlSearchParams.toString()}`;
  }
  return `/?${urlSearchParams.toString()}`;
};

const handleZipCodeFlow = async ({
  params,
}: {
  params: ZipCodeRequestParams;
}): Promise<KeywordRequestResponse | null> => {
  const { zipCode, ...otherParams } = params;
  if (zipCode) {
    return getAccessCodeByZipCode({ zipCode, ...otherParams });
  }
  return null;
};

const handleTrizettoFlow = async ({
  params,
  trizettoParams,
  step,
  oneFormData,
  updateStep,
  handleSubmitError,
  flowConfig,
  dispatcherKeyword,
  handleEnableRetry,
  payer,
  handleManualRequest,
  bhNoInsurance,
  hasSkipRedirectFlow,
  isMoveCoverageEnabled,
  isAuthCodeExpirationFieldEnabled,
  registrationAckp0CopayActive,
}: {
  params: KeywordRequestParams;
  trizettoParams: TrizettoParams;
  step: OneFormEligibilityStep;
  oneFormData: OneFormEligibilityFields;
  updateStep: UpdateStep;
  handleSubmitError: (shouldSetError?: boolean, error?: EligibilityError) => void;
  flowConfig: FlowConfig;
  dispatcherKeyword: string | null;
  handleEnableRetry?: (shouldEnableRetry: boolean) => void;
  payer?: InsuranceEligibilityPayer;
  handleManualRequest?: (args: { isManualRequest?: boolean }) => void;
  bhNoInsurance: boolean;
  hasSkipRedirectFlow: boolean;
  isMoveCoverageEnabled?: boolean;
  isAuthCodeExpirationFieldEnabled?: boolean;
  registrationAckp0CopayActive?: boolean;
}) => {
  const isBeaconBHFlow = params.flowId === FLOW_112_B2B_BH_TRIZETTO_BEACON;
  const trizettoTimeout = isBeaconBHFlow
    ? BEACON_TIMEOUT_TRIZETTO_VERIFICATION
    : TIMEOUT_TRIZETTO_VERIFICATION;

  try {
    const {
      isEligible,
      insuranceCode,
      coinsurancePercent,
      copayCents,
      deductible,
      trizettoRequestId,
      accessCode,
      accessCodeType,
      isVideoOnlyPlan,
      allowedModalities,
      totalSessions,
      accountType,
    } = await checkTrizettoEligibilityV2(trizettoParams, trizettoTimeout);

    trackEvent(isMoveCoverageEnabled ? 'Submit more details form' : 'Submit Coverage Information', {
      insuranceProvider: payer?.label || '',
      outcome: isEligible ? 'Eligible' : 'Not Eligible',
      isAuthCodeExpirationFieldEnabled: isAuthCodeExpirationFieldEnabled ?? null,
      registrationAckp0CopayFF: registrationAckp0CopayActive ?? null,
      copayAmount: (copayCents || 0) / 100,
    });

    if (isEligible === false) {
      handleSubmitError();
      return;
    }
    if (params.email) {
      await submitEmailToBraze(oneFormData.marketingConsent, params.email);
    }
    await updateStep(step.buttonTarget, {
      insuranceEligibility: {
        isEligible,
        insuranceCode,
        trizettoRequestId,
        copayCents: copayCents || 0,
        coinsurancePercent: coinsurancePercent || 0,
        deductible: deductible || 0,
        payer,
      },
      accessCode,
      accessCodeType: insuranceCode ? 'cpPartnerCode' : accessCodeType || 'qmPartnerCode',
      invalidVoucher: !isEligible || !accessCode,
      insuranceCode,
      voucher: accessCode,
      copayCents,
      trizettoRequestId,
      service: oneFormData.serviceType?.value,
      isVideoOnlyPlan,
      trizettoErrorStatusCode: 0,
      allowedModalities,
      totalSessions,
      accountType,
    });
  } catch (err) {
    const error = err as TrizettoError;

    if (isTrizettoServiceError(error)) {
      if (bhNoInsurance && handleEnableRetry) {
        handleEnableRetry(true);
        return;
      }
      if (isBeaconBHFlow && handleEnableRetry) {
        handleEnableRetry(true);
        return;
      }

      if (bhNoInsurance && hasSkipRedirectFlow && handleManualRequest) {
        handleManualRequest({ isManualRequest: true });
      }
    } else {
      handleSubmitError(true, err);
      trackEvent(
        isMoveCoverageEnabled ? 'Submit more details form' : 'Submit Coverage Information',
        {
          insuranceProvider: payer?.label || '',
          outcome: 'Verification Failed',
          isAuthCodeExpirationFieldEnabled: isAuthCodeExpirationFieldEnabled ?? null,
          registrationAckp0CopayFF: registrationAckp0CopayActive ?? null,
        }
      );
    }
  }
};

const handleTrizettoOutOfNetwork = async ({
  trizettoParams,
  step,
  updateStep,
  payer,
  registrationAckp0CopayActive,
}: {
  trizettoParams: TrizettoParams;
  step: OneFormEligibilityStep;
  updateStep: UpdateStep;
  payer?: InsuranceEligibilityPayer;
  registrationAckp0CopayActive?: boolean;
}) => {
  try {
    const {
      isEligible,
      insuranceCode,
      copayCents,
      coinsurancePercent,
      deductible,
      trizettoRequestId,
      allowedModalities = [],
      totalSessions,
      accountType,
    } = await checkTrizettoEligibilityV2(trizettoParams);

    trackEvent('Submit Coverage Information', {
      insuranceProvider: payer?.label || '',
      outcome: 'Out Of Network',
      registrationAckp0CopayFF: registrationAckp0CopayActive ?? null,
      copayAmount: (copayCents || 0) / 100,
    });

    // if the client coinsurance percent is 100 it means he needs to pay 100% of the treatment, hence we treat him as not eligible.
    if (
      !isEligible ||
      (isEligible && (coinsurancePercent === 100 || copayCents === 0)) ||
      (isEligible && copayCents === undefined && coinsurancePercent === undefined)
    ) {
      await updateStep(step.buttonTarget, {
        insuranceEligibility: {
          isEligible: false,
          ineligiblePromoCouponCode: step.ineligiblePromoCouponCode,
          ineligiblePromo: step.ineligiblePromo,
          ineligiblePromoWeeks: step.ineligiblePromoWeeks,
          payer,
        },
        allowedModalities,
        totalSessions,
        accountType,
      });

      setVWOInsuranceProvider('out-of-network');
      activateVwoOonExperiment();
      return;
    }

    await updateStep(step.buttonTarget, {
      insuranceEligibility: {
        isEligible,
        insuranceCode,
        trizettoRequestId,
        copayCents: copayCents || 0,
        coinsurancePercent: coinsurancePercent || 0,
        deductible: deductible || 0,
        payer,
      },
      allowedModalities,
      totalSessions,
      accountType,
    });
  } catch (err) {
    trackEvent('Submit Coverage Information', {
      insuranceProvider: payer?.label || '',
      outcome: 'Out Of Network',
      registrationAckp0CopayFF: registrationAckp0CopayActive ?? null,
    });

    await updateStep(step.buttonTarget, {
      insuranceEligibility: {
        isEligible: false,
        ineligiblePromoCouponCode: step.ineligiblePromoCouponCode,
        ineligiblePromo: step.ineligiblePromo,
        ineligiblePromoWeeks: step.ineligiblePromoWeeks,
        payer,
      },
    });

    setVWOInsuranceProvider('out-of-network');
    activateVwoOonExperiment();
  }
};

export const submitOneFormData = async ({
  /**
   * This flowId is used to determine which API call to make and other validation
   */
  flowId,
  flowConfig,
  step,
  oneFormData,
  contextData: {
    eligibilityWidgetHasRooms,
    isCreateRoomFlow,
    isReactivationFlow,
    isUpdateCoverageFlow,
    isMBHIneligibilityFlow,
    payer,
  },
  history,
  handleSubmitError,
  handleFlowRedirection,
  handleManualRequest,
  updateStep,
  setError,
  dispatcherKeyword,
  currentFlowID,
  displayEmployeeInfo,
  bhNoInsurance,
  handleEnableRetry,
  isMoveCoverageEnabled,
  isAuthCodeExpirationFieldEnabled,
  registrationAckp0CopayActive,
  bhPartnerID,
}: {
  flowId: number;
  flowConfig: FlowConfig | undefined;
  step: OneFormEligibilityStep;
  oneFormData: OneFormEligibilityFields;
  contextData: {
    eligibilityWidgetHasRooms?: boolean;
    isCreateRoomFlow?: boolean;
    isReactivationFlow?: boolean;
    isUpdateCoverageFlow?: boolean;
    isMBHIneligibilityFlow?: boolean;
    payer?: InsuranceEligibilityPayer | null;
  };
  history: History;
  handleSubmitError: (shouldSetError?: boolean, error?: EligibilityError) => void;
  handleFlowRedirection: (newCorrectedFlowID?: boolean) => void;
  handleManualRequest: (args: { isManualRequest?: boolean }) => void;
  updateStep: UpdateStep;
  setError: (
    name: OneFormEligibilityFieldNames,
    error: FieldError,
    config: { shouldFocus: boolean }
  ) => void;
  dispatcherKeyword: string | null;
  /** This flowId is the one in the URL. Used to check if we're embedded in another flow */
  currentFlowID: number;
  displayEmployeeInfo: boolean;
  bhNoInsurance: boolean;
  handleEnableRetry?: (shouldEnableRetry: boolean) => void;
  isMoveCoverageEnabled?: boolean;
  isAuthCodeExpirationFieldEnabled?: boolean;
  registrationAckp0CopayActive?: boolean;
  bhPartnerID?: number;
}): Promise<void> => {
  const hasSkipRedirectFlow = Boolean(step?.skipRedirectToFlow);
  const keyword = getKeyword(flowId, oneFormData, flowConfig);
  const formattedDateOfBirth = moment(oneFormData.dateOfBirth).format('YYYY-MM-DD');
  const formattedAuthCodeExpiration = oneFormData.authorizationCodeExpiration
    ? moment(oneFormData.authorizationCodeExpiration, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : undefined;
  const addressLine1Arr = oneFormData.addressLine1?.split(' ');
  const houseNum = addressLine1Arr?.[0];
  const addressStreet = addressLine1Arr?.slice(1, addressLine1Arr.length[-1]).join(' ');

  const params: KeywordRequestParams = {
    keyword,
    email: oneFormData.email,
    flowId,
    metadata: {
      firstName: oneFormData.firstName,
      lastName: oneFormData.lastName,
      dateOfBirth: formattedDateOfBirth,
      phone: oneFormData.phone,
      employeeId: oneFormData.employeeID,
      heardAbout: oneFormData.heardAbout?.value,
      employeeRelationship: oneFormData.employeeRelation?.value,
      memberId: oneFormData.memberID,
      groupId: oneFormData.groupID,
      address: houseNum,
      addressStreet,
      address2: oneFormData.addressLine2,
      city: oneFormData.city,
      state: oneFormData.clientState,
      zipcode: oneFormData.zipcode,
      country: oneFormData.country,
      authorizationCode: oneFormData.authorizationCode,
      authorizationCodeExpiration: formattedAuthCodeExpiration,
      organizationCode: oneFormData.organizationName,
      numberOfSessions: oneFormData.numberOfSessions?.value,
      attendedSchool: oneFormData.attendedSchool?.label,
      socialSecurity: oneFormData.socialSecurity || undefined,
      secondaryMemberID: oneFormData.secondaryMemberID || undefined,
      secondaryPayerID: oneFormData.secondaryPayerID || undefined,
      ...(displayEmployeeInfo && {
        employeeFirstName: oneFormData.employeeFirstName,
        employeeLastName: oneFormData.employeeLastName,
        employeeAddressLine1: oneFormData.employeeSameAddressCheckbox
          ? oneFormData.addressLine1
          : oneFormData.employeeAddressLine1,
        employeeAddressLine2: oneFormData.employeeSameAddressCheckbox
          ? oneFormData.addressLine2
          : oneFormData.employeeAddressLine2,
        employeeCity: oneFormData.employeeSameAddressCheckbox
          ? oneFormData.city
          : oneFormData.employeeCity,
        employeeState: oneFormData.employeeSameAddressCheckbox
          ? oneFormData.clientState
          : oneFormData.employeeState,
        employeeZipcode: oneFormData.employeeSameAddressCheckbox
          ? oneFormData.zipcode
          : oneFormData.employeeZipcode,
        employeeCountry: oneFormData.employeeSameAddressCheckbox
          ? oneFormData.country
          : oneFormData.employeeCountry,
      }),
    },
  };

  // Out of network
  if (payer?.id && payer?.id > 0 && !payer.keyword && flowConfig) {
    const trizettoParams: TrizettoParams = {
      gediPayerID: payer.value,
      firstName: oneFormData.firstName,
      lastName: oneFormData.lastName,
      dateOfBirth: formattedDateOfBirth,
      state: oneFormData.clientState,
      memberID: warnIfEmpty(
        trimAndLowerCaseMemberId(oneFormData.memberID),
        'memberID',
        'submitOneFormData.trizetto'
      ),
      partnerID: null,
    };

    // Does not fail
    await handleTrizettoOutOfNetwork({
      trizettoParams,
      step,
      updateStep,
      payer: payer || undefined,
      registrationAckp0CopayActive,
    });

    if (oneFormData.email) {
      // Does not fail
      await submitEmailToBraze(oneFormData.marketingConsent, oneFormData.email);
    }
    return;
  }

  if (flowConfig && flowConfig.eligibilityType === EligibilityType.trizetto) {
    if (!oneFormData.firstName || !oneFormData.lastName || !oneFormData.dateOfBirth) {
      await handleEligibilityVoucherError(updateStep, step);
      return;
    }

    let partnerID: number | null = null;
    if (
      bhPartnerID &&
      [FLOW_143_B2B_BH_TRIZETTO_DISTRICT9, FLOW_149_B2B_BH_TRIZETTO_TRICARE_EAST].includes(flowId)
    ) {
      partnerID = bhPartnerID;
    }

    const { keyword: paramsKeyword, ...otherParams } = params;
    const getVoucherParams: KeywordRequestParams = {
      ...otherParams,
      keyword: partnerID ? undefined : paramsKeyword,
    };

    const trizettoParams: TrizettoParams = {
      dateOfBirth: formattedDateOfBirth,
      firstName: oneFormData.firstName,
      flowId,
      lastName: oneFormData.lastName,
      memberID: warnIfEmpty(
        trimAndLowerCaseMemberId(oneFormData.memberID),
        'memberID',
        'submitOneFormData.trizetto'
      ),
      sendEmail: true,
      service: oneFormData.serviceType?.value || null,
      state: oneFormData.clientState,
      userEmail: oneFormData.email,
      getVoucherParams,
      partnerID,
    };
    // Doesn't throw
    await handleTrizettoFlow({
      params,
      trizettoParams,
      step,
      oneFormData,
      updateStep,
      handleSubmitError,
      flowConfig,
      dispatcherKeyword,
      handleEnableRetry,
      payer: payer || undefined,
      handleManualRequest,
      bhNoInsurance,
      hasSkipRedirectFlow,
      isMoveCoverageEnabled,
      isAuthCodeExpirationFieldEnabled,
      registrationAckp0CopayActive,
    });
    return;
  }

  let isEligible = false;
  if (
    flowConfig &&
    flowConfig.eligibilityType === EligibilityType.fileBased &&
    flowConfig.partnerID
  ) {
    try {
      const eligibilityData = await checkPartnerEligibility(
        flowConfig.partnerID,
        warnIfEmpty(
          trimAndLowerCaseMemberId(oneFormData.memberID),
          'memberID',
          'submitOneFormData.isEligible'
        ),
        formattedDateOfBirth
      );
      isEligible = eligibilityData?.isEligible;
      if (!isEligible) {
        handleSubmitError();
        return;
      }
    } catch (err) {
      // quickmatch-api looks for eligibility files based on memberID, keyword (read organization name) and member date of birth
      // and throws a 404 if that info doesn't map to a file. If possible, we should try to find a way to isolate whether the issue
      // is with the organization name or the memberID and focus the invalid field on submit i.e. use setError(fieldName, message)
      if ((err as AxiosError)?.response?.status === 404) {
        handleSubmitError();
        return;
      }
    }
  }

  try {
    const zipCodeParams: ZipCodeRequestParams = {
      zipCode: oneFormData.zipcode || null,
      flowId,
      email: oneFormData.email,
      metadata: params.metadata,
    };
    let responseData: KeywordRequestResponse | null = null;

    if (flowConfig && flowConfig.eligibilityType === EligibilityType.zipCodeEligibility) {
      responseData = await handleZipCodeFlow({ params: zipCodeParams });
    }
    if (!responseData) {
      responseData = await makeKeywordRequest(
        params,
        step.hasNumberOfSessions && keyword ? keyword : dispatcherKeyword
      );
    }

    if (oneFormData.email) {
      await submitEmailToBraze(oneFormData.marketingConsent, oneFormData.email);
    }
    const {
      accessCode,
      accessCodeType,
      allowedModalities,
      totalSessions,
      accountType,
      qmFlowID,
      redirectFrom,
    } = responseData;

    // If not in its own B2B flow
    if (
      flowId !== currentFlowID ||
      (step.buttonTarget && flowConfig?.eligibilityType === EligibilityType.zipCodeEligibility)
    ) {
      const zipCodeUpdateStepParams: Partial<
        Record<'oneFormClientState' | 'oneFormClientAge', string>
      > = {};
      if (flowConfig?.eligibilityType === EligibilityType.zipCodeEligibility) {
        if (oneFormData.clientState)
          zipCodeUpdateStepParams.oneFormClientState = oneFormData.clientState;
        if (oneFormData.dateOfBirth)
          zipCodeUpdateStepParams.oneFormClientAge = oneFormData.dateOfBirth;
      }
      await updateStep(step.buttonTarget, {
        invalidVoucher: !accessCode,
        voucher: accessCode,
        accessCode,
        accessCodeType,
        insuranceEligibility: undefined,
        allowedModalities,
        totalSessions,
        accountType,
        ...zipCodeUpdateStepParams,
        ...(redirectFrom !== qmFlowID && qmFlowID && { manualFlowID: qmFlowID }),
      });
    } else {
      const url = buildFlowPushUrl({
        accessCode,
        accessCodeType: accessCodeType as AccessCodeType,
        flowId,
        flowConfig,
        eligibilityWidgetHasRoomsParam: eligibilityWidgetHasRooms,
        isCreateRoomFlow,
        isReactivationFlow,
        isUpdateCoverageFlow,
        isMBHIneligibilityFlow,
        formattedDateOfBirth,
        serviceType: oneFormData.serviceType?.value || null,
        allowedModalities,
        totalSessions,
        accountType,
        manualFlowID: redirectFrom !== qmFlowID ? qmFlowID : undefined,
      });
      history.push(url);
    }
  } catch (err) {
    if ((err as AxiosError)?.response?.status === 412) {
      setError(
        OneFormEligibilityFieldNames.dateOfBirth,
        { type: 'invalid age', message: 'Age too young.' },
        { shouldFocus: true }
      );
      handleSubmitError(false);
      return;
    }
    if ((err as AxiosError)?.response?.status === 404 && step.optionalOrganization === false) {
      setError(
        OneFormEligibilityFieldNames.organizationName,
        { type: 'unrecognized organization', message: 'Organization not recognized.' },
        { shouldFocus: true }
      );
      handleSubmitError(false);
      return;
    }
    if (
      (err as AxiosError)?.response?.status === 400 &&
      (err as AxiosError)?.response?.data.error === 'organizationCode used on a wrong flow id'
    ) {
      handleFlowRedirection(true);
      return;
    }
    if (
      (err as AxiosError)?.response?.status === 400 &&
      (err as AxiosError)?.response?.data.error?.includes('Redeems reached for email')
    ) {
      handleSubmitError(true, 'redeemsReachedForEmailError');
      return;
    }
    if (isEligible) {
      await handleEligibilityVoucherError(updateStep, step);
    } else {
      handleSubmitError();
    }
  }
};
