import { states } from '../Helpers/locales';
import { getTherapistAbilityPreferences } from '../Helpers/therapistHelper';
import { QM_COPY } from '../utils/qmCopy';
import { QMFlow, FlowStep } from './types';
import {
  initFlowConfig,
  supportUKFlow,
  skipToStepIfClientAgeIsTeen,
  skipToStepIfClientAgeExists,
  skipToStepIfClientAgeAndStateExists,
} from './utils';

const therapistAbilityPreferences = getTherapistAbilityPreferences();

/** Generic Consumer simplified presenting problems flow */
export const FLOW_48_CONSUMER = 48;

const steps: FlowStep[] = [
  {
    id: 1,
    prompt: 'How familiar are you with therapy?',
    analyticsId: 58,
    category: 'select1',
    heading1: 'Welcome to Talkspace',
    content: [
      'We know achieving your full potential requires a lot of self-knowledge, courage, and - let’s be honest - work. With <span class="qm-talkspace-branding">Talkspace Messaging Therapy™</span>, a licensed provider will be right there with you, helping you navigate life’s ups and downs.',
      "Let's get started.",
    ],
    response_category_id: 7,
    heading2: 'Before we get started, how familiar are you with therapy?',
    buttons: [
      {
        text: "I've never been to therapy before",
        externalTarget: null,
        internalTarget: 3,
        answerValue: "I've never been to therapy before",
      },
      {
        text: "I've been to just a few therapy sessions",
        externalTarget: null,
        internalTarget: 2,
        answerValue: "I've been to just a few therapy sessions",
      },
      {
        text: "I've had at least one in-depth therapeutic experience",
        externalTarget: null,
        internalTarget: 2,
        answerValue: "I've had at least one in-depth therapeutic experience",
      },
      {
        text: "I've been in therapy for 5+ years cumulatively",
        externalTarget: null,
        internalTarget: 2,
        answerValue: "I've been in therapy for 5+ years cumulatively",
      },
    ],
  },
  {
    id: 2,
    prompt: 'How satisfied were you with your prior therapy experience?',
    analyticsId: 64,
    category: 'select1',
    heading2: 'In general, how satisfied were you with your prior therapy experience?',
    response_category_id: 7,
    buttons: [
      // all of these go to 3
      {
        text: 'Very satisfied',
        answerValue: 'Very satisfied',
        externalTarget: null,
        internalTarget: 3,
      },
      {
        text: 'Somewhat satisfied',
        answerValue: 'Somewhat satisfied',
        externalTarget: null,
        internalTarget: 3,
      },
      {
        text: 'Somewhat unsatisfied',
        answerValue: 'Somewhat unsatisfied',
        externalTarget: null,
        internalTarget: 3,
      },
      {
        text: 'Very unsatisfied',
        answerValue: 'Very unsatisfied',
        externalTarget: null,
        internalTarget: 3,
      },
    ],
    progressCurrent: 2,
    progressMax: 32,
  },
  {
    id: 3,
    prompt: 'Do you want to learn more about how therapy works?',
    analyticsId: 65,
    category: 'select1',
    heading2:
      'Thanks for sharing! Before we find you a great provider, do you want to learn more about how therapy works? Disclaimer: the movies don’t tell you everything! 🎥',
    buttons: [
      {
        text: 'I want to learn more about therapy in general',
        answerValue: 'Learn more',
        externalTarget: null,
        internalTarget: 4,
        // go to question 4
      },
      {
        text: 'I’m ready to find a provider',
        answerValue: 'Already ready',
        externalTarget: null,
        internalTarget: 10,
        // skip ahead pass this series of questions to slide 10
      },
    ],
    progressCurrent: 3,
    progressMax: 32,
    nextQuestionActions: [
      skipToStepIfClientAgeIsTeen(36, 'Already ready'), // to consent step if teen
      skipToStepIfClientAgeAndStateExists(12, 'Already ready'),
      skipToStepIfClientAgeExists(11, 'Already ready'),
    ],
  },
  {
    id: 4,
    prompt: 'So, what is therapy and how does it work?',
    analyticsId: 66,
    category: 'select1',
    heading1: 'So, what is therapy and how does it work?',
    content: [
      'Everyone has something they want to change about their lives. A great provider will help you tackle the challenges holding you back, and equip you with simple and practical tools to help you feel better <span class="qm-talkspace-branding">every day</span>.',
    ],
    heading2: 'What do people typically hope to achieve in therapy?',
    buttons: [
      // all go to slide 5
      {
        text: 'Understanding what’s bothering you and take steps to fix it',
        answerValue: 'Understanding what’s bothering you',
        externalTarget: null,
        internalTarget: 5,
      },
      {
        text: 'Understanding how you think and feel',
        answerValue: 'Understanding how you think and feel',
        externalTarget: null,
        internalTarget: 5,
      },
      {
        text: 'Understanding how you make decisions',
        answerValue: 'Understanding how you make decisions',
        externalTarget: null,
        internalTarget: 5,
      },
      {
        text: 'Helping you feel better - and stay that way',
        answerValue: 'Helping you feel better',
        externalTarget: null,
        internalTarget: 5,
      },
      {
        text: 'Helping you feel more capable and worthy',
        answerValue: 'Helping you feel more capable',
        externalTarget: null,
        internalTarget: 5,
      },
      {
        text: 'All of the above',
        answerValue: 'All of the above',
        // correct: true,
        externalTarget: null,
        internalTarget: 5,
      },
    ],
    progressCurrent: 4,
    progressMax: 32,
  },
  {
    id: 5,
    prompt: 'In what ways does emotional stress impact the body?',
    analyticsId: 67,
    category: 'select1',
    content: [
      ' Ever feel like stress puts a giant weight on your shoulders? Well, you’re not alone. Carrying stress impacts the body in many ways — from muscle tension to food cravings and beyond.',
    ],
    heading2: 'In what ways does emotional stress impact the body?',
    buttons: [
      // all of these go to 6
      {
        text: 'Poor sleep',
        answerValue: 'Poor sleep',
        externalTarget: null,
        internalTarget: 6,
      },
      {
        text: 'Excessive eating and/or drinking',
        answerValue: 'Excessive eating and/or drinking',
        externalTarget: null,
        internalTarget: 6,
      },
      {
        text: 'Lack of motivation',
        answerValue: 'Lack of motivation',
        externalTarget: null,
        internalTarget: 6,
      },
      {
        text: 'Mood swings',
        answerValue: 'Mood swings',
        externalTarget: null,
        internalTarget: 6,
      },
      {
        text: 'All of the above',
        answerValue: 'All of the above',
        externalTarget: null,
        internalTarget: 6,
      },
    ],
    progressCurrent: 5,
    progressMax: 32,
  },
  {
    id: 6,
    prompt:
      'How long would you say it takes for many people to experience a profound transformation in therapy?',
    analyticsId: 68,
    category: 'select1',
    content: [
      'Good news. The benefits of talk therapy are significant! Clients often report that the benefits of treatment actually continue to improve even after therapy is completed <i>(American Psychology Association)</i>.',
    ],
    heading2:
      'How long would you say it takes for many people to experience a profound transformation in therapy?',
    buttons: [
      // all of these go to 7
      {
        text: '3 months',
        // correct: true,
        answerValue: '3 months',
        externalTarget: null,
        internalTarget: 7,
      },
      {
        text: '6 months',
        answerValue: '6 months',
        externalTarget: null,
        internalTarget: 7,
      },
      {
        text: '1 year',
        answerValue: '1 year',
        externalTarget: null,
        internalTarget: 7,
      },
      {
        text: 'A lifetime',
        answerValue: 'A lifetime',
        externalTarget: null,
        internalTarget: 7,
      },
    ],
    progressCurrent: 6,
    progressMax: 32,
  },
  {
    id: 7,
    prompt:
      'How long would you say it typically takes for individuals to search, find, and see an in-person provider for the first time?',
    analyticsId: 69,
    category: 'select1',
    content: [
      'If you can’t imagine fitting anything else into your schedule, we get it. After a long day, exercise might sound impossible, and cooking a meal like a chore. For people seeking traditional therapy, barriers like cost, stigma, and inconvenience often get in the way and bring about more stress than solace.',
    ],
    heading2:
      'Ironic that taking care of yourself can lead to more anxiety, huh? How long would you say it typically takes for individuals to search, find, and see an in-person provider for the first time?',
    buttons: [
      // all of these go to 8
      {
        text: '2-3 days',
        answerValue: '2-3 days',
        externalTarget: null,
        internalTarget: 8,
      },
      {
        text: 'About a week',
        answerValue: 'About a week',
        externalTarget: null,
        internalTarget: 8,
      },
      {
        text: '2-3 weeks',
        answerValue: '2-3 weeks',
        externalTarget: null,
        internalTarget: 8,
      },
    ],
    progressCurrent: 7,
    progressMax: 32,
  },
  {
    id: 8,
    prompt: 'What do you think is required for a licensed provider to practice?',
    analyticsId: 70,
    category: 'select1',
    content: [
      'In today’s world of fitness apps, meditation e-courses, and sleep trackers, it’s difficult to know what will actually help you feel happier and healthier. Therapy, too, may seem like a black box. With so many types and techniques available, people are often unfamiliar with what it takes to become a licensed provider.',
    ],
    heading2: 'What do you think is required for a licensed provider to practice?',
    buttons: [
      {
        text: 'Bachelor’s degree',
        answerValue: 'Bachelor’s degree',
        externalTarget: null,
        internalTarget: 10,
      },
      {
        text: 'Bachelor’s degree + master’s degree (2 years)',
        answerValue: 'Bachelor’s degree + master’s degree',
        externalTarget: null,
        internalTarget: 10,
      },
      {
        text: 'Bachelor’s degree + master’s degree (2 years) + 2,500+ hours of patient experience',
        answerValue: 'Bachelor’s degree + master’s degree (2 years) + 2,500+ hours',
        externalTarget: null,
        internalTarget: 10,
      },
    ],
    progressCurrent: 8,
    progressMax: 32,
    nextQuestionActions: [
      skipToStepIfClientAgeIsTeen(36), // to consent step if teen
      skipToStepIfClientAgeAndStateExists(12),
      skipToStepIfClientAgeExists(11),
    ],
  },
  {
    id: 10,
    prompt: 'What is your age?',
    analyticsId: 61,
    category: 'dateOfBirth',
    heading1: 'Let’s get started',
    content: [
      'First, we need to learn a little bit about you and what brings you to Talkspace today.',
    ],
    heading2: "What's your date of birth?",
    heading3: 'As a healthcare provider, we must ask this.',
    response_category_id: 5,
    doNotAllowSearch: true,
    progressCurrent: 10,
    progressMax: 32,
    targets: {
      under13: 'https://helpnow.talkspace.com/under-13',
      teen: 36,
      adult: 11,
    },
  },
  {
    id: 11,
    prompt: QM_COPY.state.prompt,
    analyticsId: 63,
    category: 'dropdown',
    heading2: QM_COPY.state.title,
    heading3: QM_COPY.state.subtitle,
    response_category_id: 6,
    selectPlaceholder: QM_COPY.state.placeholder,
    selectOptions: states,
    buttonText: QM_COPY.state.continue,
    buttonTarget: 12,
    skipExternalTarget: null,
    skipInternalTarget: 12,
    progressCurrent: 12,
    progressMax: 32,
  },
  {
    id: 12,
    prompt: 'What led you to seek help?',
    analyticsId: 54,
    category: 'presentingProblems',
    heading2:
      'What led you to seek help today? And by the way, “I’m not sure” is a perfectly adequate response.',
    response_category_id: 2,
    internalTarget: 37,
    skipText: "I'm not sure",
    skipInternalTarget: 37,
    progressCurrent: 13,
    progressMax: 32,
    selfServe: {
      field: 'fieldsOfExpertise',
      type: Array,
    },
    tip: 'findTheRightMatch',
  },
  {
    id: 14,
    prompt: 'Preferred provider gender',
    analyticsId: 57,
    category: 'select1',
    heading2: 'I would prefer my provider to be...',
    response_category_id: 3,
    buttons: [
      {
        text: 'I have no gender preference',
        answerValue: undefined,
        externalTarget: null,
        internalTarget: 15,
      },
      {
        text: 'Female',
        answerValue: 2,
        externalTarget: null,
        internalTarget: 15,
      },
      {
        text: 'Male',
        answerValue: 1,
        externalTarget: null,
        internalTarget: 15,
      },
    ],
    progressCurrent: 16,
    progressMax: 32,
    selfServe: {
      field: 'therapistGender',
    },
  },
  // After this point all screens will have an option to skip ahead to matches
  {
    id: 15,
    prompt: 'Additional Questions',
    category: 'select1',
    heading2:
      'Got it! While we now have enough information to show you preliminary matches, we recommend answering a few additional questions to help us provide you the best possible care. Would you like to continue? Estimated time to complete: 3-5 minutes ⏱',
    buttons: [
      {
        text: "Sure! I'll answer more questions to improve my match results.",
        answerValue: "Sure! I'll answer more questions to improve my match results.",
        externalTarget: null,
        internalTarget: 16,
      },
      {
        text: "No thanks. I'm ready to see my matches.",
        answerValue: "No thanks. I'm ready to see my matches.",
        externalTarget: null,
        internalTarget: 31,
        // skip ahead to matches
      },
    ],
    progressCurrent: 17,
    progressMax: 32,
  },
  {
    id: 16,
    prompt: 'When did this/these issue(s) begin to affect you',
    analyticsId: 72,
    category: 'select1',
    heading2: 'So, when did this/these issue(s) begin affecting you?',
    response_category_id: 7,
    buttons: [
      {
        text: 'In the last 3 months. It’s very upsetting',
        answerValue: 'In the last 3 months',
        externalTarget: null,
        internalTarget: 17,
      },
      {
        text: 'A little while back, but it’s gotten much worse lately',
        answerValue: 'A little while back',
        externalTarget: null,
        internalTarget: 17,
      },
      {
        text: "A long time ago. It's finally time to work on it",
        answerValue: 'A long time ago',
        externalTarget: null,
        internalTarget: 17,
      },
      {
        text: 'Very recently. I want to work on it before it gets worse',
        answerValue: 'Very recently',
        externalTarget: null,
        internalTarget: 17,
      },
    ],
    skipText: 'Skip to view provider matches',
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 18,
    progressMax: 32,
  },
  {
    id: 17,
    category: 'multiselect',
    heading2:
      'How has your daily life been affected by the challenges you’ve been navigating? Please select all that apply.',
    prompt: 'How has your daily life been affected by the challenges you’ve been navigating?',
    analyticsId: 73,
    response_category_id: 7,
    multiselect: [
      {
        label: 'Poor sleep',
        value: 'Poor sleep',
      },
      {
        label: 'Unstable physical health',
        value: 'Unstable physical health',
      },
      {
        label: 'Low energy level',
        value: 'Low energy level',
      },
      {
        label: 'Poor eating habits',
        value: 'Poor eating habits',
      },
      {
        label: 'Substance use (drugs/alcohol/tobacco and caffeine)',
        value: 'Substance use (drugs/alcohol/tobacco and caffeine)',
      },
      {
        label: 'Poor relationships',
        value: 'Poor relationships',
      },
      {
        label: 'Low satisfaction with work and life',
        value: 'Low satisfaction with work and life',
      },
      {
        label: 'None of the above',
        value: 'none',
      },
    ],
    externalTarget: null,
    internalTarget: 18,
    submitText: 'Continue',
    skipText: 'Skip to view provider matches',
    goToMatches: true,
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 19,
    progressMax: 32,
  },
  {
    prompt: "What do you do when you're stressed?",
    analyticsId: 74,
    id: 18,
    category: 'multiselect',
    heading2:
      'Sometimes our efforts to confront stress make things worse, and cause us to spiral. Which, if any, are true for you when you’re stressed?',
    response_category_id: 7,
    multiselect: [
      // this creates a queue of future questions
      {
        label: 'I become overwhelmed',
        value: 'I become overwhelmed',
        addQuestionToQueue: 19,
      },
      {
        label: 'I lose control of my behavior',
        value: 'I lose control of my behavior',
        addQuestionToQueue: 20,
      },
      {
        label: 'I get stuck in my head',
        value: 'I get stuck in my head',
        addQuestionToQueue: 21,
      },
      {
        label: 'I worry things will never change',
        value: 'I worry things will never change',
        addQuestionToQueue: 22,
      },
      {
        label: 'None of these',
        value: 'none',
      },
    ],
    externalTarget: null,
    internalTarget: 23,
    submitText: 'Continue',
    skipText: 'Skip to view provider matches',
    goToMatches: true,
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 20,
    progressMax: 32,
  },
  {
    id: 19,
    prompt: 'When Im overwhelmed, I tend to...',
    analyticsId: 75,
    category: 'multiselect',
    heading2: 'When I’m overwhelmed, I tend to...',
    response_category_id: 7,
    multiselect: [
      {
        label: 'Withdraw socially',
        value: 'Withdraw socially',
      },
      {
        label: 'Feel “blah,” like I’m lacking a purpose',
        value: 'Feel “blah,” like I’m lacking a purpose',
      },
      {
        label: 'Wonder what I did wrong',
        value: 'Wonder what I did wrong',
      },
      {
        label: 'Forget how I feel and stay busy',
        value: 'Forget how I feel and stay busy',
      },
      {
        label: 'None of the above',
        value: 'none',
      },
    ],
    externalTarget: null,
    internalTarget: 23,
    submitText: 'Continue',
    skipText: 'Skip to view provider matches',
    goToMatches: true,
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 21,
    progressMax: 32,
  },
  {
    id: 20,
    prompt: 'When I lose control I tend to',
    analyticsId: 76,
    category: 'multiselect',
    heading2: 'When I lose control I tend to...',
    response_category_id: 7,
    multiselect: [
      {
        label: 'Drink more or use drugs',
        value: 'Drink more or use drugs',
      },
      {
        label: 'Jump from one relationship to another',
        value: 'Jump from one relationship to another',
      },
      {
        label: 'Have riskier sex',
        value: 'Have riskier sex',
      },
      {
        label: 'Snap at others (e.g. a coworker/boss or loved one)',
        value: 'Snap at others',
      },
      {
        label: 'Ditch my responsibilities',
        value: 'Ditch my responsibilities',
      },
      {
        label: 'Spend money unwisely',
        value: 'Spend money unwisely',
      },
      {
        label: 'None of the above',
        value: 'None of the above',
      },
    ],
    externalTarget: null,
    internalTarget: 23,
    submitText: 'Continue',
    skipText: 'Skip to view provider matches',
    goToMatches: true,
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 22,
    progressMax: 32,
  },
  {
    id: 21,
    prompt: 'When I get stuck in my head, I tend to:',
    analyticsId: 77,
    category: 'multiselect',
    response_category_id: 7,
    heading2: 'When I get stuck in my head, I tend to...',
    multiselect: [
      {
        label: 'Worry about the future',
        value: 'Worry about the future',
      },
      {
        label: 'Think about things that have gone wrong in the past',
        value: 'Think about things that have gone wrong in the past',
      },
      {
        label: 'Think about how I bring things on myself',
        value: 'Think about how I bring things on myself',
      },
      {
        label: 'Think about the things others have done wrong',
        value: 'Think about the things others have done wrong',
      },
      {
        label: 'Think about why bad things always happen',
        value: 'Think about why bad things always happen',
      },
      {
        label: 'None of the above',
        value: 'none',
      },
    ],
    externalTarget: null,
    internalTarget: 23,
    submitText: 'Continue',
    skipText: 'Skip to view provider matches',
    goToMatches: true,
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 23,
    progressMax: 32,
  },
  {
    id: 22,
    prompt: "When I worry things will never change, I fear that i'll: ",
    analyticsId: 78,
    category: 'multiselect',
    heading2: 'When I worry things will never change, I fear that I’ll...',
    response_category_id: 7,
    multiselect: [
      {
        label: 'Never be loved or cared for or understood',
        value: 'Never be loved or cared for or understood',
      },
      {
        label: 'Never feel free to do what I think is best',
        value: 'Never feel free to do what I think is best',
      },
      {
        label: 'Never amount to anything or make a difference',
        value: 'Never amount to anything or make a difference',
      },
      {
        label: 'Never be free from the pain I feel',
        value: 'Never be free from the pain I feel',
      },
      {
        label: 'None of the above',
        value: 'none',
      },
    ],
    externalTarget: null,
    internalTarget: 23,
    submitText: 'Continue',
    skipText: 'Skip to view provider matches',
    goToMatches: true,
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 24,
    progressMax: 32,
  },
  {
    id: 23,
    prompt: 'I become annoyed when',
    analyticsId: 79,
    category: 'multiselect',
    heading2: 'I become annoyed when',
    response_category_id: 7,
    multiselect: [
      {
        label:
          "Someone doesn't simply listen and instead offers suggestions or advice. I'm usually able to find my own solutions once I'm ready.",
        value: "Someone doesn't simply listen",
      },
      {
        label:
          'Someone only listens and never offers me any suggestions or things I can learn to improve.',
        value:
          'Someone only listens and never offers me any suggestions or things I can learn to improve',
      },
      {
        label: "I don't have a strong preference either way.",
        value: 'none',
        // acts like a none of the above
      },
    ],
    externalTarget: null,
    internalTarget: 24,
    submitText: 'Continue',
    skipText: 'Skip to view provider matches',
    goToMatches: true,
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 25,
    progressMax: 32,
  },
  {
    id: 24,
    prompt: 'What is your current outlook? ',
    analyticsId: 80,
    category: 'select1',
    heading2: 'Which of the following best describes your current outlook?',
    response_category_id: 7,
    buttons: [
      {
        text: 'I’m in an OK place. Mostly, I’m struggling with difficult people and/or life circumstances',
        answerValue: 'I’m in an OK place',
        externalTarget: null,
        internalTarget: 25,
      },
      {
        text: 'I need to change something about myself to solve my problems, but I’m not sure what. I’d want to talk it through for a few weeks before taking any action',
        answerValue: 'I need to change something about myself',
        externalTarget: null,
        internalTarget: 25,
      },
      {
        text: 'I have a pretty good sense of what my problems are, though I need support in making a plan to work on them. I’d like to take action in the next month',
        answerValue: 'I have a pretty good sense of what my problems are',
        externalTarget: null,
        internalTarget: 25,
      },
    ],
    skipText: 'Skip to view provider matches',
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 26,
    progressMax: 32,
  },
  {
    id: 25,
    prompt: 'What time of day do you find yourself most introspective?',
    analyticsId: 81,
    category: 'select1',
    heading1: 'Finding the right provider is a two-way street',
    content: [
      'The next set of questions are designed to help us recommend providers who have achieved great clinical outcomes with people whose preferences and backgrounds are similar to yours. The more you tell us, the better our recommendations are.',
    ],
    heading2: 'What time of day do you find yourself most introspective?',
    response_category_id: 7,
    buttons: [
      {
        text: 'Morning, just as I’m starting my day',
        answerValue: 'Morning, just as I’m starting my day',
        externalTarget: null,
        internalTarget: 26,
      },
      {
        text: 'Intermittently throughout the day',
        answerValue: 'Intermittently throughout the day',
        externalTarget: null,
        internalTarget: 26,
      },
      {
        text: 'After work when I come home',
        answerValue: 'After work when I come home',
        externalTarget: null,
        internalTarget: 26,
      },
      {
        text: 'Late at night when I can’t sleep',
        answerValue: 'Late at night when I can’t sleep',
        externalTarget: null,
        internalTarget: 26,
      },
      {
        text: 'Other',
        answerValue: 'Other',
        externalTarget: null,
        internalTarget: 26,
      },
    ],
    skipText: 'Skip to view provider matches',
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 27,
    progressMax: 32,
  },
  {
    id: 26,
    prompt: 'How do you primarily communicate with the closest people in your life today?',
    analyticsId: 82,
    category: 'select1',
    heading2: 'How do you primarily communicate with the closest people in your life today?',
    response_category_id: 7,
    buttons: [
      {
        text: 'Text',
        answerValue: 'Text',
        externalTarget: null,
        internalTarget: 27,
      },
      {
        text: 'Phone call',
        answerValue: 'Phone call',
        externalTarget: null,
        internalTarget: 27,
      },
      {
        text: 'Face-to-face',
        answerValue: 'Face-to-face',
        externalTarget: null,
        internalTarget: 27,
      },
      {
        text: 'Social media',
        answerValue: 'Social media',
        externalTarget: null,
        internalTarget: 27,
      },
    ],
    skipText: 'Skip to view provider matches',
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 28,
    progressMax: 32,
  },
  {
    id: 27,
    prompt: 'I want a provider who...',
    analyticsId: 83,
    category: 'select1',
    heading2:
      'Fill in the rest of this sentence with your top preference. I want a provider who...',
    response_category_id: 7,
    buttons: [
      {
        text: 'Will mostly ask questions that support my exploration',
        answerValue: 'Will mostly ask questions that support my exploration',
        externalTarget: null,
        internalTarget: 28,
      },
      {
        text: 'Helps identify patterns, and shares direct insights and advice with me',
        answerValue: 'Helps identify patterns, and shares direct insights and advice with me',
        externalTarget: null,
        internalTarget: 28,
      },
      {
        text: 'Pushes me to do things I may be afraid to do that support my larger goals',
        answerValue: 'Pushes me to do things I may be afraid to do that support my larger goals',
        externalTarget: null,
        internalTarget: 28,
      },
      {
        text: 'Structures therapy with homework and ongoing accountability exercises',
        answerValue: 'Structures therapy with homework and ongoing accountability exercises',
        externalTarget: null,
        internalTarget: 28,
      },
      {
        text: 'I don’t know yet',
        answerValue: 'I don’t know yet',
        externalTarget: null,
        internalTarget: 28,
      },
    ],
    skipText: 'Skip to view provider matches',
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 29,
    progressMax: 32,
  },
  {
    id: 28,
    prompt: 'My gender is',
    analyticsId: 62,
    category: 'select1',
    heading2: 'My gender is',
    response_category_id: 4,
    buttons: [
      {
        text: 'Male',
        answerValue: 1,
        externalTarget: null,
        internalTarget: 29,
      },
      {
        text: 'Female',
        answerValue: 2,
        externalTarget: null,
        internalTarget: 29,
      },
      {
        text: 'Transgender male',
        answerValue: 3,
        externalTarget: null,
        internalTarget: 29,
      },
      {
        text: 'Transgender female',
        answerValue: 4,
        externalTarget: null,
        internalTarget: 29,
      },
      {
        text: 'Gender queer',
        answerValue: 5,
        externalTarget: null,
        internalTarget: 29,
      },
      {
        text: 'Gender variant',
        answerValue: 6,
        externalTarget: null,
        internalTarget: 29,
      },
      {
        text: 'Other',
        answerValue: 7,
        externalTarget: null,
        internalTarget: 29,
      },
      {
        text: 'Non-binary',
        answerValue: 8,
        externalTarget: null,
        internalTarget: 29,
      },
    ],
    skipText: 'Skip to view provider matches',
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 30,
    progressMax: 32,
  },
  {
    id: 29,
    prompt: 'What is your education level? ',
    analyticsId: 84,
    category: 'select1',
    heading2: 'My education level is',
    response_category_id: 7,
    buttons: [
      {
        text: 'Less than a high school diploma',
        answerValue: 'Less than a high school diploma',
        externalTarget: null,
        internalTarget: 30,
      },
      {
        text: 'High School Diploma (or GED)',
        answerValue: 'High School Diploma (or GED)',
        externalTarget: null,
        internalTarget: 30,
      },
      {
        text: 'Some college or no degree',
        answerValue: 'Some college or no degree',
        externalTarget: null,
        internalTarget: 30,
      },
      {
        text: 'Associate’s Degree',
        answerValue: 'Associate’s Degree',
        externalTarget: null,
        internalTarget: 30,
      },
      {
        text: 'Bachelor’s Degree',
        answerValue: 'Bachelor’s Degree',
        externalTarget: null,
        internalTarget: 30,
      },
      {
        text: 'Master’s degree',
        answerValue: 'Master’s degree',
        externalTarget: null,
        internalTarget: 30,
      },
      {
        text: 'Professional degree',
        answerValue: 'Professional degree',
        externalTarget: null,
        internalTarget: 30,
      },
      {
        text: 'Doctoral degree',
        answerValue: 'Doctoral degree',
        externalTarget: null,
        internalTarget: 30,
      },
    ],
    skipText: 'Skip to view provider matches',
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 31,
    progressMax: 32,
  },
  {
    id: 30,
    prompt: 'Marital status',
    analyticsId: 85,
    category: 'select1',
    heading2: 'I’m currently',
    response_category_id: 7,
    buttons: [
      {
        text: 'Single',
        answerValue: 'Single',
        externalTarget: null,
        internalTarget: 31,
      },
      {
        text: 'In a relationship',
        answerValue: 'In a relationship',
        externalTarget: null,
        internalTarget: 31,
      },
      {
        text: 'Living with a partner',
        answerValue: 'Living with a partner',
        externalTarget: null,
        internalTarget: 31,
      },
      {
        text: 'Married',
        answerValue: 'Married',
        externalTarget: null,
        internalTarget: 31,
      },
      {
        text: 'Separated',
        answerValue: 'Separated',
        externalTarget: null,
        internalTarget: 31,
      },
      {
        text: 'Divorced',
        answerValue: 'Divorced',
        externalTarget: null,
        internalTarget: 31,
      },
      {
        text: 'Widowed',
        answerValue: 'Widowed',
        externalTarget: null,
        internalTarget: 31,
      },
    ],
    skipText: 'Skip to view provider matches',
    skipExternalTarget: null,
    skipInternalTarget: 31,
    progressCurrent: 32,
    progressMax: 32,
  },
  // and now, the matches!

  {
    id: 31,
    prompt: 'Loading',
    category: 'loading1',
    buttonTarget: 32,
    hideBackButton: true,
  },
  {
    id: 32,
    prompt: 'Matches',
    category: 'matches1',
    buttonTarget: 39,
    zeroMatchTarget: 33,
    matchOverlay: true,
    canMatchOverlayBeSkipped: true,
    showCustomerRegistrationModalWithoutPayment: true,
  },
  {
    id: 33,
    category: 'chooseAndPayClientWeb',
    offerID: 62,
    previousStepsUntilMatches: 3,
  },
  {
    id: 35,
    prompt: 'Update Nickname and Password',
    category: 'updateNicknamePassword1',
    hideBackButton: true,
  },
  {
    id: 36,
    prompt: 'Consent',
    analyticsId: 562,
    category: 'select1',
    heading2:
      'Note that based on your age and state of residency, a parent or guardian consent may be needed to receive therapy. If this cannot work for you, you can try another resource. Does this work for you?',

    buttons: [
      {
        text: 'Yes, I understand',
        answerValue: 'Yes, I understand',
        externalTarget: null,
        internalTarget: 11,
      },
      {
        text: 'No, I need more information',
        answerValue: 'No, I need more information',
        externalTarget: 'https://helpnow.talkspace.com/under-18-no-consent',
        internalTarget: null,
      },
    ],
    progressCurrent: 11,
    progressMax: 32,
    nextQuestionActions: [skipToStepIfClientAgeAndStateExists(12, 'Yes, I understand')],
  },
  {
    id: 37,
    prompt: 'Describe your goals',
    analyticsId: 86,
    category: 'freeText',
    response_category_id: 7,
    internalTarget: 38,
    heading2: 'Okay, try to describe what your goals are in your own words.',
    submitText: 'Continue',
    skipText: 'Skip',
    skipInternalTarget: 38,
    progressCurrent: 14,
    progressMax: 32,
  },
  {
    id: 38,
    prompt: 'I am looking for a provider that will: ',
    analyticsId: 87,
    category: 'dropdown',
    heading2: 'I am looking for a provider that will:',
    response_category_id: 7,
    submitText: 'Continue',
    selectOptions: therapistAbilityPreferences,
    selectPlaceholder: 'Please select one option',
    externalTarget: null,
    internalTarget: 14,
    buttonTarget: 14,
    progressCurrent: 15,
    progressMax: 32,
  },
  {
    id: 39,
    category: 'matchConfirmation',
    buttonTarget: 40,
  },
  {
    id: 40,
    prompt: 'Scheduler',
    category: 'scheduler',
    buttonTarget: 33,
  },
];

const flow: QMFlow = {
  flowId: FLOW_48_CONSUMER,
  flowConfig: {
    ...initFlowConfig,
    isB2B: false,
    isTeen: true,
    version: 5,
    disabled: true,
  },
  steps,
};

export default supportUKFlow(flow);
