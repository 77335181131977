import { FunctionComponent } from 'react';
import {
  View,
  TSLogo,
  ScrollView,
  PanelHeader,
  ResponsiveLayoutWithHeader,
  useWindowWidth,
} from '@talkspace/react-toolkit';

interface Props {
  onScrollChange?: (scrollTop: number) => void;
}

const Screen: FunctionComponent<Props> = ({ children, onScrollChange }) => {
  const { isMobile } = useWindowWidth();

  return (
    <ResponsiveLayoutWithHeader
      shouldScroll={false}
      renderHeader={() => (
        <PanelHeader
          renderLeft={() => (
            <View flex={1} align="start">
              <TSLogo variant="2024" />
            </View>
          )}
        />
      )}
    >
      <ScrollView onScrollChange={onScrollChange} style={{ padding: !isMobile ? 22 : 0 }}>
        {children}
      </ScrollView>
    </ResponsiveLayoutWithHeader>
  );
};

export default Screen;
