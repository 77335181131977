import { FunctionComponent, useState, useCallback, useEffect } from 'react';
import { Trans } from '@talkspace/i18n';
import {
  useEmotionTheme,
  Button,
  Huge,
  ExtraHuge,
  Large,
  Tiny,
  Small,
  View,
  Modal,
  Big,
  ComplianceBadges,
  PlanDetails,
  X,
  TouchableView,
  TakeTheLeap,
  Check,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { ServiceType } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils/sessionStorageService';

import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import styled from '@/core/styled';
import { ReviewPlanStep, UpdateStep } from '@/Flows/types';

import StickyFooter from '@/Components/StickyFooter';
import {
  parseTextInBullets,
  PLAN_REVIEW_BH,
  PLAN_REVIEW_EAP_MESSAGE,
  PLAN_REVIEW_EAP_VIDEO,
  PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE,
  ReviewPlans,
} from '@/utils/reviewPlan';
import useWindowWidth from '@/hooks/useWindowWidth';
import { getCopayCost } from '@/Components/Steps/RegisterWithBHCopay';
import { trackEvent } from '@/utils/analytics/events';
import { useHomePageActions } from '@/Components/HomePage';

interface BaseReviewPlanProps {
  planDetails?:
    | typeof PLAN_REVIEW_BH
    | typeof PLAN_REVIEW_EAP_MESSAGE
    | typeof PLAN_REVIEW_EAP_VIDEO
    | typeof PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE;
  step: ReviewPlanStep;
  updateStep: UpdateStep;
  finalFlowID: number;
  copayCents?: number;
  totalSessions: number;
  service: ServiceType | null;
}

const MainContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    if (isMobile) {
      return { width: 335, overflowX: 'auto' };
    }
    return { width: 430 };
  }
);

const TakeTheLeapImageContainer = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      backgroundColor: colors.permaFeta,
      height: 200,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20 0',
      gap: 10,
      width: isMobile ? 335 : 430,
      borderRadius: 16,
    };
  }
);

const TitleContainer = styled(ExtraHuge)({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  marginTop: 24,
  textAlign: 'left',
});

const ListContainer = styled(ExtraHuge)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: 16,
      width: isMobile ? 335 : 430,
    };
  }
);
const ListWrapper = styled(View)({
  alignItems: 'baseline',
  flexDirection: 'row',
  textAlign: 'left',
});

const ListTextWrapper = styled(Large)({
  marginBottom: 15,
});

const TinyTextWrapper = styled(Tiny)(({ theme: { colors } }) => {
  return {
    color: colors.permaFiord,
    textAlign: 'left',
  };
});

const SmallTextWrapper = styled(Small)<{ textColor: string }>(({ textColor }) => {
  return {
    color: textColor,
    marginTop: 16,
    textAlign: 'left',
  };
});

const MoreDetailsWrapper = styled(View)<{ textColor: string }>(({ textColor }) => {
  return {
    color: textColor,
    fontSize: 14,
    marginTop: 16,
    textAlign: 'left',
    fontWeight: 'bold',
    width: 200,
    cursor: 'pointer',
  };
});

const HorizontalLine = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    marginTop: 15,
    borderTop: `1px solid ${colors.permaMischkaNew}`,
  };
});

const HugeTextWrapper = styled(Huge)(({ theme: { colors } }) => {
  return {
    color: colors.permaBlueStoneNew,
    marginTop: 16,
    textAlign: 'left',
  };
});

const SuperTextWrapper = styled(View)({
  fontSize: 16,
  paddingLeft: 3,
  paddingTop: 15,
  alignSelf: 'start',
});

const CostOfServiceWrapper = styled(Large)(({ theme: { colors } }) => {
  return {
    color: colors.permaOuterSpaceNew,
    marginTop: 4,
    textAlign: 'left',
  };
});

const StyledButton = styled(Button)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? 335 : 430,
      marginTop: 16,
      transition: 'all 200ms ease-in-out',
      '&:not([disabled]):hover': {
        backgroundColor: colors.permaTropicalRainForest,
      },
    };
  }
);

const ModalBigText = styled(Big)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    if (isMobile) {
      return {
        alignSelf: 'center',
        width: 320,
        paddingLeft: 115,
      };
    }
    return {
      fontSize: 29,
      alignSelf: 'center',
    };
  }
);

const BaseReviewPlan: FunctionComponent<BaseReviewPlanProps> = ({
  planDetails,
  step,
  updateStep,
  finalFlowID,
  copayCents,
  totalSessions,
  service,
}) => {
  const { getMaxCost } = useHomePageActions();
  const { registrationAckp0Copay: registrationAckp0CopayActive } = useFlags();

  const maxCost = getMaxCost(finalFlowID, service);

  let bullets = planDetails?.bullets;
  const bulletsTranslationKeys = planDetails?.bulletsTranslationKeys || [];

  const {
    category,
    titleText,
    titleTextTranslationKey,
    disclaimerText,
    disclaimerTextTranslationKey,
    modalDetails,
  } = planDetails || {
    category: '',
    modalDetails: {
      topText: '',
      topTextTranslationKey: undefined,
      paragraphs: [],
    },
  };

  useEffect(() => {
    trackEvent('Covered by Health Insurance Seen', {
      funnelName: 'QuickMatch',
      stepId: step.id,
      stepName: 'Covered by Health insurance',
      category,
    });
  }, [step.id, category]);

  const [showModal, setShowModal] = useState(false);
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidth();
  const isEAP =
    ReviewPlans.PLAN_REVIEW_EAP_MESSAGE === category ||
    ReviewPlans.PLAN_REVIEW_EAP_VIDEO === category ||
    ReviewPlans.PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE === category;

  const isBH = category === ReviewPlans.PLAN_REVIEW_BH;

  const copayDollars = isBH && copayCents && copayCents > 0 ? getCopayCost(copayCents, maxCost) : 0;

  const goForward = useCallback(() => {
    trackEvent('Covered by Health Insurance CTA', {
      funnelName: 'QuickMatch',
      stepId: step.id,
      stepName: 'Covered by Health insurance',
      category,
    });
    updateStep(step.buttonTarget);
  }, [step.id, category, step.buttonTarget, updateStep]);

  const titleId = useUniqueID('titleId');

  const { bookedTherapistName, bookedTherapistLicense } = getSessionStorageValuesFromJson(
    SESSION_STORAGE_MEMBER_DETAILS_KEY,
    ['bookedTherapistName', 'bookedTherapistLicense']
  );

  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');

  if (bullets) {
    bullets = parseTextInBullets({
      bullets,
      bookedTherapistName,
      bookedTherapistLicense,
      totalSessions,
      bulletsTranslationKeys,
      tQuickmatchCommon,
    });
  }

  return (
    <>
      <MainContainer>
        <TakeTheLeapImageContainer>
          <TakeTheLeap />
        </TakeTheLeapImageContainer>
        <TitleContainer>
          <Trans t={tQuickmatchCommon} i18nKey={titleTextTranslationKey as any}>
            {titleText}
          </Trans>
        </TitleContainer>
        <ListContainer>
          {bullets &&
            bullets.map((therapyBullet, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListWrapper key={`bullet-${i}`}>
                <View style={{ marginRight: 15 }}>
                  <Check width={11} height={9} />
                </View>
                <ListTextWrapper>{therapyBullet}</ListTextWrapper>
              </ListWrapper>
            ))}
        </ListContainer>
        <TinyTextWrapper>
          <Trans t={tQuickmatchCommon} i18nKey={disclaimerTextTranslationKey as any}>
            {disclaimerText}
          </Trans>
        </TinyTextWrapper>
        <MoreDetailsWrapper
          onClick={() => {
            trackEvent('More Details View', {
              funnelName: 'QuickMatch',
              stepId: step.id,
              stepName: 'Covered by Health insurance',
              category,
            });
            setShowModal(true);
          }}
          textColor={colors.permaBlueStoneNew}
        >
          <Trans t={tQuickmatchCommon} i18nKey="reviewPlanStep.moreDetailsLinkText">
            More Details
          </Trans>
        </MoreDetailsWrapper>
      </MainContainer>
      {isMobile && <HorizontalLine />}
      <StickyFooter>
        {isEAP && ( // For EAP below price needs to be hardcoded.
          <ListWrapper>
            <HugeTextWrapper>
              <Trans t={tQuickmatchCommon} i18nKey="reviewPlanStep.priceEAPText">
                $0 per session
              </Trans>
            </HugeTextWrapper>
            <SuperTextWrapper>*</SuperTextWrapper>
          </ListWrapper>
        )}
        {isBH && (!registrationAckp0CopayActive || copayDollars > 0) && (
          <>
            <ListWrapper>
              <HugeTextWrapper>
                <Trans t={tQuickmatchCommon} i18nKey="reviewPlanStep.priceBHCopayText">
                  ${{ copay_dollars: copayDollars }} copay per session
                </Trans>
              </HugeTextWrapper>

              <SuperTextWrapper>*</SuperTextWrapper>
            </ListWrapper>
            <CostOfServiceWrapper>
              <Trans t={tQuickmatchCommon} i18nKey="reviewPlanStep.priceBHMaximumText">
                Maximum cost of service: ${{ max_cost: maxCost }} /session
              </Trans>
            </CostOfServiceWrapper>
          </>
        )}
        <StyledButton
          data-qa="BaseReviewPlanContinueButton"
          style={{ fontWeight: 700 }}
          primaryColor={colors.green}
          roundedFocusStyle
          onClick={goForward}
        >
          <Trans t={tQuickmatchCommon} i18nKey="reviewPlanStep.continueButtonText">
            Continue
          </Trans>
        </StyledButton>
      </StickyFooter>
      <Modal
        onBackdropPress={() => setShowModal(false)}
        titleText="Plan Details"
        titleID={titleId}
        isVisible={showModal}
      >
        <Modal.Panel
          contentViewStyle={{
            ...(!isMobile && {
              paddingLeft: 15,
              paddingRight: 15,
              paddingBottom: 0,
            }),
          }}
          showCloseButton={false}
          onBackdropPress={() => setShowModal(false)}
        >
          {!isMobile && (
            <View style={{ alignSelf: 'center' }}>
              <PlanDetails />
            </View>
          )}
          <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
            <ModalBigText>
              <Trans t={tQuickmatchCommon} i18nKey="reviewPlanStep.modalTitleText">
                Plan Details
              </Trans>
            </ModalBigText>
            {isMobile && (
              <TouchableView onPress={() => setShowModal(false)}>
                <X />
              </TouchableView>
            )}
          </View>

          <HorizontalLine />
          <SmallTextWrapper textColor={colors.permaFiord} variant="smallBoldGrey">
            {modalDetails.topTextTranslationKey && (
              <Trans t={tQuickmatchCommon} i18nKey={modalDetails.topTextTranslationKey as any}>
                {modalDetails.topText}
              </Trans>
            )}
          </SmallTextWrapper>
          <>
            {modalDetails.paragraphs.map((paragraph, i) => (
              <View>
                {/* i == 0 will yield true for first paragraph to style it differently. */}
                {i === 0 && (
                  <Big style={{ marginTop: 6, color: colors.permaOuterSpaceNew, fontWeight: 400 }}>
                    <Trans t={tQuickmatchCommon} i18nKey={paragraph.titleTranslationKey}>
                      {paragraph.title}
                    </Trans>
                  </Big>
                )}
                {i !== 0 && (
                  <SmallTextWrapper textColor={colors.permaFiord} variant="smallBoldGrey">
                    <Trans t={tQuickmatchCommon} i18nKey={paragraph.titleTranslationKey}>
                      {paragraph.title}
                    </Trans>
                  </SmallTextWrapper>
                )}
                {i === 0 && (
                  <Large style={{ marginTop: 6, color: colors.permaFiord }}>
                    <Trans t={tQuickmatchCommon} i18nKey={paragraph.descriptionTranslationKey}>
                      {paragraph.description}
                    </Trans>
                  </Large>
                )}
                {i !== 0 && (
                  <SmallTextWrapper textColor={colors.permaFiord}>
                    <Trans t={tQuickmatchCommon} i18nKey={paragraph.descriptionTranslationKey}>
                      {paragraph.description}
                    </Trans>
                  </SmallTextWrapper>
                )}
                <HorizontalLine style={{ marginTop: 20 }} />
              </View>
            ))}
          </>
          <ComplianceBadges style={{ alignSelf: 'center', marginTop: 13, marginBottom: 26 }} />
        </Modal.Panel>
      </Modal>
    </>
  );
};

export default BaseReviewPlan;
