import { initFlowConfig, skipToStepIfClientAgeExists, skipToStepIfClientAgeIsTeen } from './utils';
import { EligibilityType, QMFlow } from './types';
import { FLOW_94_B2B_BH_TRIZETTO_HMA } from './Flow94';
import { FLOW_92_B2B_BH_TRIZETTO_RGA } from './Flow92';
import { FLOW_91_B2B_COPAY_TRIZETTO_OPTUM_UMR } from './Flow91';
import { FLOW_86_B2B_BH_TRIZETTO_CIGNA_ALLEGIANCE } from './Flow86';
import { FLOW_84_B2B_BH_TRIZETTO_REGENCE } from './Flow84';
import { FLOW_70_B2B_BH_TRIZETTO_AETNA } from './Flow70';
import { FLOW_59_B2B_BH_TRIZETTO_CIGNA } from './Flow59';
import { FLOW_33_B2B_BH_FILE_GATORCARE } from './Flow33';
import { FLOW_32_B2B_COPAY_TRIZETTO_OPTUM } from './Flow32';
import { FLOW_28_B2B_COPAY_TRIZETTO_PREMERA } from './Flow28';
import { FLOW_110_B2B_BH_TRIZETTO_GATORCARE } from './Flow110';
import { FLOW_112_B2B_BH_TRIZETTO_BEACON } from './Flow112';
import { FLOW_105_B2B_FIRST_CHOICE_BH } from './Flow105';
import { FLOW_115_B2B_BH_TRIZETTO_SUREST } from './Flow115';
import { FLOW_117_B2B_BH_TRIZETTO_MERITAIN } from './Flow117';
import { FLOW_120_B2B_BH_TRIZETTO_TYSON } from './Flow120';
import { FLOW_121_B2B_BH_TRIZETTO_EMBLEM } from './Flow121';
import { FLOW_124_B2B_BH_TRIZETTO_UNICARE } from './Flow124';
import { FLOW_126_B2B_BH_TRIZETTO_PROVIDENCE } from './Flow126';
import { FLOW_130_B2B_BH_TRIZETTO_ANTHEM } from './Flow130';
import { FLOW_134_B2B_BH_TRIZETTO_ASURIS } from './Flow134';
import { FLOW_136_B2B_BH_TRIZETTO_BRIDGESPAN } from './Flow136';
import { FLOW_139_TEMP_MEDICARE } from './Flow139';
import { FLOW_143_B2B_BH_TRIZETTO_DISTRICT9 } from './Flow143';
import { FLOW_145_B2B_BH_TRIZETTO_MEDICARE } from './Flow145';
import { FLOW_149_B2B_BH_TRIZETTO_TRICARE_EAST } from './Flow149';
import { states } from '../Helpers/locales';
import { AccountType, HomePageState } from '../Components/HomePage/types';
import { shouldSkipOrRemovePreferredModality } from '../Helpers/preferredModalityHelper';
// eslint-disable-next-line import/no-cycle
import { getClientFlowConfig } from './index';
import { presentingProblemSteps } from './steps';

const allBHCopayFlows = [
  FLOW_28_B2B_COPAY_TRIZETTO_PREMERA,
  FLOW_32_B2B_COPAY_TRIZETTO_OPTUM,
  FLOW_59_B2B_BH_TRIZETTO_CIGNA,
  FLOW_70_B2B_BH_TRIZETTO_AETNA,
  FLOW_84_B2B_BH_TRIZETTO_REGENCE,
  FLOW_86_B2B_BH_TRIZETTO_CIGNA_ALLEGIANCE,
  FLOW_91_B2B_COPAY_TRIZETTO_OPTUM_UMR,
  FLOW_92_B2B_BH_TRIZETTO_RGA,
  FLOW_94_B2B_BH_TRIZETTO_HMA,
  FLOW_110_B2B_BH_TRIZETTO_GATORCARE,
  FLOW_112_B2B_BH_TRIZETTO_BEACON,
  FLOW_105_B2B_FIRST_CHOICE_BH,
  FLOW_115_B2B_BH_TRIZETTO_SUREST,
  FLOW_117_B2B_BH_TRIZETTO_MERITAIN,
  FLOW_120_B2B_BH_TRIZETTO_TYSON,
  FLOW_121_B2B_BH_TRIZETTO_EMBLEM,
  FLOW_124_B2B_BH_TRIZETTO_UNICARE,
  FLOW_126_B2B_BH_TRIZETTO_PROVIDENCE,
  FLOW_130_B2B_BH_TRIZETTO_ANTHEM,
  FLOW_134_B2B_BH_TRIZETTO_ASURIS,
  FLOW_136_B2B_BH_TRIZETTO_BRIDGESPAN,
  FLOW_139_TEMP_MEDICARE,
  FLOW_143_B2B_BH_TRIZETTO_DISTRICT9,
  FLOW_145_B2B_BH_TRIZETTO_MEDICARE,
  FLOW_149_B2B_BH_TRIZETTO_TRICARE_EAST,
];

const allStatesIncludingDC = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'IL',
  'ID',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'ME',
  'MD',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

// as of CLINICAL-5489 this includes all states, including DC
const flow28availablePsychiatryStates = [...allStatesIncludingDC];

// as of CLINICAL-5489 this includes all states, including DC
const flow59availablePsychiatryStates = [...allStatesIncludingDC];

// as of CLINICAL-5152 this includes all states, including DC
const flow32AvailablePsychiatryStates = [...allStatesIncludingDC];

const flow91AvailablePsychiatryStates = [...allStatesIncludingDC];

const flow33AvailablePsychiatryStates = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

const skipStepsForPsychiatryBH = (state: HomePageState) => {
  // skip a few steps for psychiatry users for BH Copay flows
  const { redirectFrom, service } = state;

  if (allBHCopayFlows.includes(redirectFrom) && service === 'psychiatry') return 11;
  return undefined;
};

const skipToPsychiatryUnavailableForState = (state: HomePageState, answer: any = {}) => {
  // display error message if state is not supported for psychiatry service
  const { redirectFrom, service } = state;
  if (
    service &&
    service === 'psychiatry' &&
    ((redirectFrom === FLOW_28_B2B_COPAY_TRIZETTO_PREMERA &&
      !flow28availablePsychiatryStates.includes(answer.response_value)) ||
      (redirectFrom === FLOW_59_B2B_BH_TRIZETTO_CIGNA &&
        !flow59availablePsychiatryStates.includes(answer.response_value)) ||
      (redirectFrom === FLOW_32_B2B_COPAY_TRIZETTO_OPTUM &&
        !flow32AvailablePsychiatryStates.includes(answer.response_value)) ||
      (redirectFrom === FLOW_33_B2B_BH_FILE_GATORCARE &&
        !flow33AvailablePsychiatryStates.includes(answer.response_value)) ||
      (redirectFrom === FLOW_91_B2B_COPAY_TRIZETTO_OPTUM_UMR &&
        !flow91AvailablePsychiatryStates.includes(answer.response_value)))
  ) {
    return 14;
  }
  return undefined;
};

const skipModalitySelectionIfIrrelevant = (state: HomePageState) => {
  if (shouldSkipOrRemovePreferredModality(state)) {
    return 20;
  }

  return undefined;
};

const skipReviewPlanIfIrrelevant = (state: HomePageState) => {
  const { accountType } = state;
  const isPsychiatry = state?.service === 'psychiatry';
  const manualFlowID = state?.manualFlowID;

  const manualFlowConfig = manualFlowID ? getClientFlowConfig(manualFlowID) : undefined;
  const isManualFlow =
    manualFlowConfig?.eligibilityType === EligibilityType.manual ||
    getClientFlowConfig(state.redirectFrom)?.eligibilityType === EligibilityType.manual;

  if (
    accountType &&
    [AccountType.EAP, AccountType.BH].includes(accountType) &&
    !isManualFlow &&
    !isPsychiatry
  ) {
    return undefined;
  }
  return skipModalitySelectionIfIrrelevant(state) || 30; // go to modalities
};

/** Generic COPAY (glg + ends of all copay flows) */
export const FLOW_7_B2B_COPAY_GENERIC = 7;

const flow: QMFlow = {
  flowId: FLOW_7_B2B_COPAY_GENERIC,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    version: 3,
  },
  steps: [
    {
      id: 1,
      prompt: 'Welcome to QuickMatch',
      analyticsId: 53,
      category: 'introduction1',
      response_category_id: 1,
      progressCurrent: 0,
      progressMax: 10,
      heading1: 'Welcome to <br/>Talkspace QuickMatch <sup>TM</sup>',
      heading2:
        "In the next 90 seconds you'll learn everything you need to know about how Talkspace works and get matched with a provider that can help you.",
      buttonText: "Let's start!",
      buttonTarget: 2,
    },
    ...presentingProblemSteps(
      { singleStepID: 2, target: 4, multiStepID: 40 },
      {
        nextQuestionActions: [skipStepsForPsychiatryBH],
        progressCurrent: 1,
        progressMax: 10,
      }
    ),
    {
      id: 4,
      prompt: 'Here are things to know about Therapy',
      analyticsId: 56,
      category: 'explanation',
      progressCurrent: 2,
      progressMax: 10,
      response_category_id: 1,
      heading1: null,
      heading2: 'There are two important things to know about how Talkspace works',
      explanationGroups: [
        {
          text: 'You can send your provider text, audio and video messages whenever you want.',
          tooltipText:
            'This means that you will be communicating with your provider by typing in a secure chat room. Online therapy is a clinically valid and convenient way of working with a provider.',
        },
        {
          text: 'Your provider is available to engage daily, 5 days a week.',
          tooltipText:
            "You can write into the secure chat room whenever you feel you’re ready. You don't have to wait for your next appointment. If you need more or less, you can always arrange it with your provider.",
        },
      ],
      buttonText: 'Got it!',
      buttonTarget: 5,
    },
    {
      id: 5,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      category: 'select1',
      progressCurrent: 3,
      response_category_id: 3,
      progressMax: 10,
      heading1: null,
      heading2: 'Would you prefer a provider that is...',
      heading3: 'Please answer a few quick questions to narrow down your match.',
      buttons: [
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 1,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 2,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 6,
          answerValue: undefined,
        },
      ],
      selfServe: {
        field: 'therapistGender',
      },
    },
    {
      id: 6,
      prompt: 'How familiar are you with therapy?',
      analyticsId: 58,
      category: 'select1',
      progressCurrent: 4,
      response_category_id: 7,
      progressMax: 10,
      heading2: 'Have you been to a provider before?',
      buttons: [
        {
          text: 'Yes',
          externalTarget: null,
          internalTarget: 7,
          answerValue: 'Yes',
        },
        {
          text: 'No',
          externalTarget: null,
          internalTarget: 7,
          answerValue: 'No',
        },
      ],
    },
    {
      id: 7,
      prompt: 'How would you rate your sleeping habits?',
      analyticsId: 59,
      category: 'select1',
      response_category_id: 7,
      progressCurrent: 5,
      progressMax: 10,
      heading2: 'How would you rate your sleeping habits?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Poor',
        },
      ],
      tip: 'findTheRightMatch',
    },
    {
      id: 8,
      prompt: 'How would you rate your physical health?',
      analyticsId: 60,
      category: 'select1',
      progressCurrent: 6,
      response_category_id: 7,
      progressMax: 10,
      heading2: 'How would you rate your current physical health?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Poor',
        },
      ],
    },
    {
      id: 9,
      prompt: 'My gender is',
      analyticsId: 62,
      category: 'select1',
      progressCurrent: 7,
      response_category_id: 4,
      progressMax: 10,
      heading2: 'Please select your gender.',
      buttons: [
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 1,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 2,
        },
        {
          text: 'Transgender male',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 3,
        },
        {
          text: 'Transgender female',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 4,
        },
        {
          text: 'Gender queer',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 5,
        },
        {
          text: 'Gender variant',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 6,
        },
        {
          text: 'Other',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 7,
        },
        {
          text: 'Non-binary',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 8,
        },
      ],
      skipText: 'Skip',
      skipExternalTarget: null,
      skipInternalTarget: 10,
      nextQuestionActions: [skipToStepIfClientAgeIsTeen(23), skipToStepIfClientAgeExists(11)],
    },
    {
      id: 10,
      prompt: 'What is your age?',
      analyticsId: 61,
      category: 'dateOfBirth',
      response_category_id: 5,
      progressCurrent: 8,
      progressMax: 10,
      heading2: 'Great! To improve your match, please tell us your age.',
      targets: {
        under13: 'https://helpnow.talkspace.com/under-13',
        teen: 23,
        adult: 11,
      },
    },
    {
      id: 23,
      prompt: 'Consent',
      analyticsId: 562,
      category: 'select1',
      heading2:
        'Based on your age and state, parent or guardian consent may be needed. Does this work for you?',
      progressCurrent: 8,
      progressMax: 10,
      buttons: [
        {
          text: 'Yes',
          answerValue: 'Yes',
          externalTarget: null,
          internalTarget: 11,
        },
        {
          text: 'No, show me other resources',
          answerValue: 'No, show me other resources',
          externalTarget: 'https://helpnow.talkspace.com/under-18-no-consent',
          internalTarget: null,
        },
      ],
    },
    {
      id: 11,
      prompt: 'What is your state?',
      analyticsId: 63,
      category: 'dropdown',
      progressCurrent: 9,
      response_category_id: 6,
      progressMax: 10,
      heading2: 'Please select your state of residence.',
      selectPlaceholder: 'Select a state',
      selectOptions: states,
      buttonText: 'Submit',
      buttonTarget: 12,
      skipExternalTarget: null,
      skipInternalTarget: 12,
      nextQuestionActions: [skipToPsychiatryUnavailableForState],
    },
    {
      id: 12,
      prompt: 'Loading',
      category: 'loading1',
      buttonTarget: 13,
      hideBackButton: true,
    },
    {
      id: 13,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 24,
      zeroMatchTarget: 31,
      nextQuestionActions: {
        31: [skipReviewPlanIfIrrelevant],
      },
    },
    {
      id: 24,
      category: 'matchConfirmation',
      buttonTarget: 25,
    },
    {
      id: 14,
      category: 'psychiatryUnavailable',
      description:
        'We are sorry but no psychiatrists are available in your state at this moment. You can either work with a provider on the Talkspace platform, or reach out to your health plan using the number on the back of your member ID card.',
      hideBackButton: true,
    },
    {
      id: 30,
      category: 'sessionModality',
      buttonTarget: 20,
    },
    {
      id: 31,
      category: 'reviewPlan',
      buttonTarget: 30,
      nextQuestionActions: [skipModalitySelectionIfIrrelevant],
    },
    {
      id: 20,
      category: 'BHCopay',
      hideBackButton: true,
    },
    {
      id: 21,
      prompt: 'Update Nickname and Password',
      category: 'updateNicknamePassword1',
      hideBackButton: true,
    },
    {
      id: 25,
      prompt: 'Scheduler',
      category: 'scheduler',
      buttonTarget: 31,
      nextQuestionActions: [skipReviewPlanIfIrrelevant],
    },
  ],
};

export default flow;
