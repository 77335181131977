import { EmotionThemeProvider } from '@talkspace/react-toolkit';
import { QueryClientProvider, QueryClient } from 'react-query';

import { Router, Route, Switch, useHistory } from '@/core/routerLib';
import Directory from '@/directory/Directory';
import { DirectoryProvider } from '@/directory/DirectoryContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const CatchAll = () => {
  const history = useHistory();
  history.replace('/');
  return null;
};

const DirectoryRouter = () => (
  <EmotionThemeProvider version="2.0.0">
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <Route
            path="/ai"
            exact
            render={() => (
              <DirectoryProvider mode="standard">
                <Directory />
              </DirectoryProvider>
            )}
          />

          <Route
            path="/ai/dev"
            exact
            render={() => (
              <DirectoryProvider mode="dev">
                <Directory />
              </DirectoryProvider>
            )}
          />

          <Route component={CatchAll} />
        </Switch>
      </Router>
    </QueryClientProvider>
  </EmotionThemeProvider>
);

export default DirectoryRouter;
