/* eslint-disable import/prefer-default-export */
import { SearchParams, SearchParamsDTO } from '@/directory/types';

export const formatSearchParams = (params: SearchParams): SearchParamsDTO => {
  return {
    ...params,
    gender: params.gender?.value || '',
    state: params.state?.value || '',
    payer: params.payer?.value || '',
    specialty: params.specialty?.value || '',
    communicationPreferences: params.communicationPreferences?.value || '',
    conversationalDynamics: params.conversationalDynamics?.value || '',
  };
};
