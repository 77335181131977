import { AvatarSearchParams, SearchParams } from '@/directory/types';

export const therapistsQueryKey = (params: SearchParams) => [
  'directory-therapists',
  JSON.stringify(params),
];

export const therapistAvatarQueryKey = (params: AvatarSearchParams) => [
  'directory-therapists',
  'avatar',
  JSON.stringify(params),
];
