import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import Lottie from 'react-lottie';
import {
  getSpacing,
  styled,
  Tag,
  TextDS,
  TouchableView,
  useWindowWidth,
  View,
} from '@talkspace/react-toolkit';
import { DirectoryProvider } from '@/directory/types';
import { useDirectoryState } from '@/directory/DirectoryContext';
import useMutationTherapistAvatar from '@/directory/useMutationTherapistAvatar';
import spinnerJSON from '@/directory/spinner.json';

interface FirstMessageTagProps {
  text: string;
  isLoading: boolean;
  onPress: () => void;
}

const FirstMessageTag = ({ text, isLoading, onPress }: FirstMessageTagProps) => (
  <TouchableView
    row
    align="center"
    justify="start"
    onPress={isLoading ? undefined : onPress}
    style={{ height: 40 }}
  >
    <Tag text={text} color="brandDefault" />

    {isLoading && (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: spinnerJSON,
        }}
        height={50}
        width={50}
        style={{ margin: 0 }}
      />
    )}
  </TouchableView>
);

const MainView = styled(View)(
  ({
    theme: {
      colorRoles,
      window: { isMobile },
    },
  }) => {
    return {
      flexDirection: isMobile ? 'column' : 'row',
      gap: isMobile ? getSpacing('space300') : getSpacing('space600'),
      padding: isMobile ? getSpacing('space200') : getSpacing('space300'),
      borderBottom: `1px solid ${colorRoles.system.borderSubtle}`,
      alignItems: 'center',
    };
  }
);

const AvatarView = styled(TouchableView)({
  position: 'relative',
  width: 250,
  height: 250,
  flexDirection: 'column',
  gap: getSpacing('space100'),
  alignItems: 'center',
});

const getMediaStyles = (isVisible: boolean) => {
  return {
    position: 'absolute',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 1s ease-in-out',
    opacity: isVisible ? 1 : 0,
  };
};

const Image = styled.img<{ isVisible: boolean }>(({ isVisible }) => getMediaStyles(isVisible));

const Video = styled.video<{ isVisible: boolean }>(({ isVisible }) => getMediaStyles(isVisible));

const DetailsView = styled(View)({
  flexDirection: 'column',
  gap: getSpacing('space100'),
});

const feelingsMap1 = {
  anxiety: 'anxious',
  depression: 'depressed',
  stress: 'stressed',
  relationships: 'concerned about my relationships',
  couple: 'concerned about my relationship',
  marital: 'concerned about my marriage',
  teen: 'overwhelmed as a teenager',
  addiction: 'struggling with addiction',
  insomnia: 'unable to sleep',
};

const feelingsMap2 = {
  anxiety: 'anxiety',
  depression: 'depression',
  stress: 'stress',
  relationships: 'relationship issues',
  couple: 'relationship issues',
  marital: 'marital issues',
  teen: 'teenage challenges',
  addiction: 'addiction',
  insomnia: 'insomnia',
};

const feelingsMap3 = {
  anxiety: 'managing my anxiety',
  depression: 'coping with depression',
  stress: 'dealing with stress',
  relationships: 'improving my relationships',
  couple: 'navigating couple issues',
  marital: 'addressing marital problems',
  teen: 'handling teenage challenges',
  addiction: 'overcoming addiction',
  insomnia: 'treating insomnia',
};

const getFirstMessageTemplates = (specialty?: string) =>
  !specialty
    ? []
    : [
        `"Hi, I've been feeling ${feelingsMap1[specialty]} lately and I wanted to talk to someone about it."`,
        `"I'm not sure where to begin, but I've been experiencing some ${feelingsMap2[specialty]} and I need some help navigating it."`,
        `"Hello, I'm looking for guidance on ${feelingsMap3[specialty]}."`,
        `"I want to understand why I'm feeling this way and how I can manage it better. Can you help me?"`,
      ];

interface Props {
  therapist: DirectoryProvider;
}

const TherapistCard = ({ therapist }: Props) => {
  const { isMobile, isTablet } = useWindowWidth();

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const { searchParams } = useDirectoryState();

  const [firstMessageTemplates, setFirstMessageTemplates] = useState(() =>
    getFirstMessageTemplates(searchParams.specialty?.value)
  );

  const {
    data: avatarData,
    mutateAsync: generateAvatar,
    isLoading: isLoadingAvatar,
    reset,
  } = useMutationTherapistAvatar();

  useEffect(() => {
    if (searchParams.specialty?.value) {
      setFirstMessageTemplates(getFirstMessageTemplates(searchParams.specialty.value));
    }
  }, [searchParams.specialty]);

  const handleCTAPress = async (firstMessage: string) => {
    setFirstMessageTemplates((prev) => prev.filter((it) => it === firstMessage));
    await generateAvatar({ ...searchParams, therapistID: therapist.id, firstMessage });
  };

  const handleVideoCanPlay = () => {
    setIsVideoLoaded(true);
  };

  const handleVideoEnded = () => {
    setIsVideoLoaded(false);
    reset();
    setFirstMessageTemplates(getFirstMessageTemplates(searchParams.specialty?.value));
  };

  return (
    <MainView>
      <AvatarView>
        {avatarData && (
          <Video
            autoPlay
            src={avatarData?.videoURL}
            onCanPlay={handleVideoCanPlay}
            onEnded={handleVideoEnded}
            isVisible={isVideoLoaded}
          />
        )}
        <Image
          src={therapist.imageURL}
          alt={`${therapist.firstName} ${therapist.lastName}`}
          isVisible={!avatarData || (!!avatarData && !isVideoLoaded)}
        />
      </AvatarView>

      <View>
        <DetailsView>
          <View>
            <TextDS variant="headingLg">
              {therapist.firstName} {therapist.lastName}, {therapist.credentials[0].name}
            </TextDS>

            <TextDS variant="bodySm" colorRole="textSubtlest">
              Next available: Jul 19th, 8:00pm
            </TextDS>
          </View>

          <TextDS
            style={{ maxWidth: 500 }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(therapist.dynamicBio),
            }}
          />

          <View align="start" justify="start" style={{ gap: getSpacing('space050') }}>
            {firstMessageTemplates.map((firstMessageTemplate) => (
              <FirstMessageTag
                key={firstMessageTemplate}
                text={firstMessageTemplate}
                onPress={() => handleCTAPress(firstMessageTemplate)}
                isLoading={isLoadingAvatar}
              />
            ))}
          </View>
        </DetailsView>

        {(isMobile || isTablet) && (
          <img
            style={{ marginTop: getSpacing('space200') }}
            src="/schedule.png"
            width={360}
            height={276}
            alt={`${therapist.firstName} ${therapist.lastName} schedule`}
          />
        )}
      </View>

      {!isMobile && !isTablet && (
        <img
          src="/schedule.png"
          width={360}
          height={276}
          alt={`${therapist.firstName} ${therapist.lastName} schedule`}
        />
      )}
    </MainView>
  );
};

export default TherapistCard;
