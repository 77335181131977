import apiHelper from '@/core/api/apiHelper';
import { AvatarSearchParams } from '@/directory/types';
import { formatSearchParams } from '@/directory/utils';
import useMutationWithCache from '@/directory/useMutationWithCache';
import { therapistAvatarQueryKey } from '@/directory/queryKeys';

interface TherapistAvatarResponse {
  content: string;
  videoURL: string;
}

const fetchTherapistAvatar = async (
  params: AvatarSearchParams
): Promise<TherapistAvatarResponse> => {
  const apiParams = formatSearchParams(params);

  const nonEmptyPayload = {};

  Object.keys(apiParams).forEach((key) => {
    if (key !== 'therapistID' && apiParams[key]) {
      nonEmptyPayload[key] = apiParams[key];
    }
  });

  const response = await fetch(
    `${apiHelper().publicEndpoint}public/v3/therapists/${params.therapistID}/get-video-url`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(nonEmptyPayload),
    }
  );

  const { data } = await response.json();

  return data;
};

const useMutationTherapistAvatar = () =>
  useMutationWithCache<TherapistAvatarResponse, AvatarSearchParams>(
    fetchTherapistAvatar,
    therapistAvatarQueryKey
  );

export default useMutationTherapistAvatar;
