/**
 * Flow: 123
 * DISABLED
 * Description:
 *
 * This is a temporary (experimental) clone of flow 66. If a user is assigned the experimental variant
 * of the two-step checkout experiment, they are sent to this flow instead.
 */
import { AccountType, HomePageState } from '../Components/HomePage/types';
import { states } from '../Helpers/locales';
import { shouldSkipOrRemovePreferredModality } from '../Helpers/preferredModalityHelper';
import getParamByName from '../utils/queryString';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
// eslint-disable-next-line import/no-cycle
import { getClientFlow, getClientFlowConfig } from './index';
// eslint-disable-next-line import/no-cycle
import { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
// eslint-disable-next-line import/no-cycle
import { FLOW_7_B2B_COPAY_GENERIC } from './Flow7';

import { EligibilityType, OneFormEligibilityStep, QMFlow, UpdateStepObj } from './types';
import {
  allRegistrationPagesSteps,
  ineligiblePromo,
  initFlowConfig,
  registrationStepsValidation,
} from './utils';
import { QM_COPY } from '../utils/qmCopy';
// eslint-disable-next-line import/no-cycle

const OFFER_ID = 62;
const INITIAL_OFFER_STEP_ID = 24;

export const getRegistrationStepIDByFlowID = (
  state: HomePageState,
  answer?: UpdateStepObj
): number => {
  const { organizationFlowID, partnerFlowID } = state;
  let { qmPartnerCode, cpPartnerCode } = state;
  let accessCodeType = getParamByName('accessCodeType') as AccessCodeType | null;

  if (
    !(accessCodeType || qmPartnerCode || cpPartnerCode) &&
    (answer?.accessCodeType || answer?.accessCode)
  ) {
    accessCodeType = answer.accessCodeType || null;
    // Attempt to find the cpPartnerCode first
    if (accessCodeType && accessCodeType === 'cpPartnerCode') {
      cpPartnerCode = answer.accessCode || '';
    }
    // Default to qmPartnerCode
    if (!cpPartnerCode) {
      qmPartnerCode = answer.accessCode || '';
    }
  }
  const flowID = organizationFlowID || partnerFlowID;
  let flow = flowID && getClientFlow(flowID);
  if (cpPartnerCode || accessCodeType === 'cpPartnerCode') {
    flow = getClientFlow(FLOW_7_B2B_COPAY_GENERIC);
  }
  if (qmPartnerCode || accessCodeType === 'qmPartnerCode') {
    flow = getClientFlow(FLOW_138_B2B_VOUCHER_GENERIC_V2);
  }
  if (flow) {
    const registrationStepIndexes = flow.steps
      .map((step) => registrationStepsValidation.findIndex((validationFun) => validationFun(step)))
      .filter((index) => index > -1);
    if (registrationStepIndexes.length > 0) {
      return INITIAL_OFFER_STEP_ID + registrationStepIndexes[0];
    }
  }
  return INITIAL_OFFER_STEP_ID;
};

const skipModalitySelectionIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const allowedModalities = answer?.allowedModalities || state.allowedModalities || undefined;
    if (shouldSkipOrRemovePreferredModality({ ...state, allowedModalities })) {
      return skipToStepID || getRegistrationStepIDByFlowID(state, answer); // skip to registration if no modalities found.
    }
    return undefined;
  };

const skipReviewPlanIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const accountType = answer?.accountType || state.accountType;
    const manualFlowID = answer?.manualFlowID || state.manualFlowID;
    const isCouldNotBeVerified = answer?.isCouldNotBeVerified || state.isCouldNotBeVerified;

    const manualFlowConfig = manualFlowID ? getClientFlowConfig(manualFlowID) : undefined;
    const isManualFlow =
      manualFlowConfig?.eligibilityType === EligibilityType.manual ||
      getClientFlowConfig(state.organizationFlowID || state.partnerFlowID || state.redirectFrom)
        ?.eligibilityType === EligibilityType.manual;
    if (accountType && [AccountType.EAP, AccountType.BH].includes(accountType) && !isManualFlow) {
      return undefined;
    }
    if (isCouldNotBeVerified) {
      return undefined;
    }
    return skipModalitySelectionIfIrrelevant(skipToStepID)(state, answer) || 30; // go to modalities
  };

const showB2BForkFirst = (target: number) => (state: HomePageState) => {
  if (state.isReactivationFlow || state.isUpdateCoverageFlow || state.isMBHIneligibilityFlow) {
    return target;
  }
  return undefined;
};

const skipEligibilityForDTE =
  (b2bSkipTarget: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const { organizationFlowID } = answer || {};
    // If we successfully received a qmFlowID and the target flow has an eligibility type, show form
    if (
      organizationFlowID &&
      getClientFlowConfig(organizationFlowID)?.eligibilityType !== undefined
    ) {
      return undefined;
    }
    return showB2BForkFirst(b2bSkipTarget)(state) || 22;
  };

// Omitting category for readability
const ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG: Omit<OneFormEligibilityStep, 'id' | 'category'> = {
  analyticsId: 54,
  response_category_id: 9,
  // nextQuestionActions: [goToMatchesOrRegistration],
  ...ineligiblePromo,
};

/** Service Specific Funnels: Therapy */
export const FLOW_123_CONSUMER_THERAPY = 123;

const flow: QMFlow = {
  flowId: FLOW_123_CONSUMER_THERAPY,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'psychotherapy',
    isB2B: false,
    isTeen: true,
    shouldHideServiceSelection: true,
    version: 3,
    disabled: true,
  },
  steps: [
    {
      id: 1,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      response_category_id: 2,
      category: 'presentingProblems',
      internalTarget: 3,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      heading1: null,
      heading2: "To begin, tell us why you're looking for help today.",
      progressCurrent: 1,
      progressMax: 11,
      tip: 'findTheRightMatch',
    },
    {
      id: 3,
      prompt: 'How would you rate your sleeping habits?',
      analyticsId: 59,
      response_category_id: 7,
      category: 'select1',
      heading2: 'How would you rate your sleeping habits?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Poor',
        },
      ],
      progressCurrent: 2,
      progressMax: 11,
    },
    {
      id: 4,
      prompt: 'How would you rate your physical health?',
      analyticsId: 60,
      response_category_id: 7,
      category: 'select1',
      heading2: 'How would you rate your current physical health?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Poor',
        },
      ],
      progressCurrent: 3,
      progressMax: 11,
    },
    // matching questions
    {
      id: 11,
      prompt: 'My gender is',
      analyticsId: 62,
      response_category_id: 4,
      category: 'select1',
      heading2: 'What gender do you identify with?',
      buttons: [
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 1,
        },
        {
          text: 'Transgender female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 4,
        },
        {
          text: 'Transgender male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 3,
        },
        {
          text: 'Gender queer',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 5,
        },
        {
          text: 'Gender variant',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 6,
        },
        {
          text: 'Other',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 7,
        },
        {
          text: 'Non-binary',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 8,
        },
      ],
      skipText: 'Skip',
      skipExternalTarget: null,
      skipInternalTarget: 12,
      progressCurrent: 4,
      progressMax: 11,
    },
    {
      id: 12,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      response_category_id: 3,
      category: 'select1',
      heading2: 'What gender would you prefer in a provider?',
      selfServe: {
        field: 'therapistGender',
      },
      buttons: [
        {
          text: 'No preference',
          externalTarget: null,
          internalTarget: 13,
          answerValue: undefined,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 1,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 13,
          answerValue: undefined,
        },
      ],
      progressCurrent: 5,
      progressMax: 11,
    },
    {
      id: 13,
      prompt: 'What is your age?',
      analyticsId: 61,
      response_category_id: 5,
      category: 'dateOfBirth',
      heading2: "What's your date of birth?",
      heading3: 'As a healthcare provider, we must ask this.',
      targets: {
        adult: 14,
        teen: 21,
        under13: 'https://helpnow.talkspace.com/under-13',
      },
      progressCurrent: 6,
      progressMax: 11,
    },
    {
      id: 14,
      prompt: QM_COPY.state.prompt,
      analyticsId: 63,
      response_category_id: 6,
      category: 'dropdown',
      heading2: QM_COPY.state.title,
      heading3: QM_COPY.state.subtitle,
      selectPlaceholder: QM_COPY.state.placeholder,
      selectOptions: states,
      buttonText: QM_COPY.state.continue,
      buttonTarget: 18,
      skipExternalTarget: null,
      progressCurrent: 8,
      progressMax: 11,
      nextQuestionActions: [showB2BForkFirst(15)],
    },
    {
      id: 15,
      category: 'b2bFork',
      buttonTarget: 22,
      dispatcherStepTarget: 16,
      ctSplitTarget: 23,
      progressCurrent: 9,
      progressMax: 11,
      nextQuestionActions: { 22: [showB2BForkFirst(18)] },
    },
    {
      id: 16,
      category: 'dispatcherInQM',
      initialDispatcherStep: 'organizationName',
      internalTarget: 17,
      progressCurrent: 10,
      progressMax: 11,
      nextQuestionActions: [skipEligibilityForDTE(18)],
    },
    {
      id: 17,
      category: 'oneFormEligibility', // EAP
      progressCurrent: 11,
      progressMax: 11,
      buttonTarget: 22,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
      nextQuestionActions: [showB2BForkFirst(18)],
    },
    {
      id: 18,
      category: 'currentProvider',
      buttonTarget: 19,
      currentProviderTarget: 15,
      nextQuestionActions: { 15: [showB2BForkFirst(22)] },
    },
    {
      id: 19,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 15,
      zeroMatchTarget: 15,
      matchOverlay: true,
      matchOverlayCampaignType: 'quickmatch90_1',
      canMatchOverlayBeSkipped: true,
      showCustomerRegistrationModalWithoutPayment: true,
      nextQuestionActions: [showB2BForkFirst(22)],
    },
    {
      id: 20,
      category: 'matchConfirmation',
      buttonTarget: 22,
    },
    // teen consent (age 13 - 18)
    {
      id: 21,
      prompt: 'Consent',
      analyticsId: 562,
      response_category_id: 7, // TODO
      heading2:
        'Based on your age and state, parent or guardian consent may be needed. Does this work for you?',
      category: 'select1',
      buttons: [
        {
          text: 'Yes',
          answerValue: 'Yes',
          externalTarget: null,
          internalTarget: 14,
        },
        {
          text: 'No, show me other resources',
          answerValue: 'No, show me other resources',
          externalTarget: 'https://helpnow.talkspace.com/under-18-no-consent',
          internalTarget: null,
        },
      ],
      progressCurrent: 7,
      progressMax: 11,
    },
    // 21 is Already occupied by teen consent
    {
      id: 22,
      category: 'oneFormEligibility', // BH
      buttonTarget: 32,
      noCoverageButtonTarget: INITIAL_OFFER_STEP_ID,
      nextQuestionActions: [skipReviewPlanIfIrrelevant()],
      disableOrganization: true,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
    },
    { id: 23, category: 'registerWithoutPayment' },
    {
      id: 30,
      category: 'sessionModality',
      buttonTarget: INITIAL_OFFER_STEP_ID,
      nextQuestionActions: [getRegistrationStepIDByFlowID],
    },
    {
      id: 32,
      category: 'reviewPlan',
      buttonTarget: 30,
      nextQuestionActions: [skipModalitySelectionIfIrrelevant()],
    },
    ...allRegistrationPagesSteps({
      initialStepID: INITIAL_OFFER_STEP_ID,
      offersPageSettings: {
        offerID: OFFER_ID,
        previousStepsUntilMatches: 2,
      },
    }),
  ],
};

export default flow;
