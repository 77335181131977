// eslint-disable-next-line no-unused-vars
import { View, CloseButton, TSLogo, ExtraBig } from '@talkspace/react-toolkit';
import { forwardRef } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import ReactFrameService from '../../utils/reactFrame/ReactFrameService';
import styled, { EmotionStyle } from '../../core/styled';
import { apply1000pxMediaQuery } from '../../core/styled/styleHelpers';

const HeaderWrapper = styled(View)<{ hideHeaderMobile?: boolean | null; style?: EmotionStyle }>(
  ({ hideHeaderMobile, style, theme: { colors } }) => {
    return {
      position: 'fixed',
      zIndex: 2,
      left: 0,
      height: '6.8rem',
      padding: '0 15px',
      width: '100%',
      backgroundColor: colors.white,
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.0394305)',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      '@media screen (max-width: 750px)': {
        ...(hideHeaderMobile && { display: 'none' }),
      },
      ...apply1000pxMediaQuery({ alignItems: 'center' }),
      ...style,
    };
  }
);

const Header = forwardRef<
  HTMLDivElement,
  {
    hideHeaderMobile?: boolean | null | undefined;
    style?: EmotionStyle;
    stepCategory?: string;
    closeButton?: JSX.Element;
    flowId?: number;
    isUpdateCoverageFlow?: boolean | null;
  }
>(({ hideHeaderMobile, style, stepCategory, closeButton, flowId, isUpdateCoverageFlow }, ref) => {
  const { brandRefreshChanges } = useFlags();
  const { insuranceConfirmationStep } = useFlags();
  const showCloseButton =
    closeButton || (ReactFrameService.instance().isInFrame() && ReactFrameService.isMobile());

  return (
    <HeaderWrapper as="header" hideHeaderMobile={hideHeaderMobile} style={style} ref={ref}>
      {isUpdateCoverageFlow &&
      insuranceConfirmationStep.flowId === flowId &&
      insuranceConfirmationStep.variant === 'treatment' ? (
        <ExtraBig>Update Coverage</ExtraBig>
      ) : (
        <TSLogo
          dataQa={`${stepCategory || ''}Logo`}
          height={brandRefreshChanges ? undefined : 32}
          variant={brandRefreshChanges ? '2024' : 'old'}
        />
      )}
      {showCloseButton && (
        <View
          style={{
            width: 35,
            height: 35,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            right: '2rem',
          }}
        >
          {closeButton || (
            <CloseButton
              dataQa={`${stepCategory || ''}Close`}
              onPress={() => ReactFrameService.instance().closePopup()}
              width={35}
              height={35}
            />
          )}
        </View>
      )}
    </HeaderWrapper>
  );
});

export default Header;
