import { FC } from 'react';
import ReactSelect, { OptionsType, ValueType, StylesConfig } from 'react-select';
import { COLORS } from '@talkspace/react-toolkit';

interface OptionType {
  value: string;
  label: string;
}

const selectStyles: StylesConfig<OptionType, false> = {
  indicatorSeparator: () => {
    return { display: 'none' };
  },

  control: (provided) => {
    return {
      ...provided,
      width: '100%',
      height: 50,
      border: '1px solid #dfe1e5',
      borderRadius: '8px',
      padding: '8px 12px',
      boxShadow: 'none',
      minHeight: 40,
      fontSize: 14,
      color: '#202124',
      '&:hover': {
        border: '1px solid #c6c6c6',
      },
    };
  },

  menu: (provided) => {
    return {
      ...provided,
      borderRadius: 8,
      overflow: 'hidden',
    };
  },

  option: (provided, state) => {
    let backgroundColor = '#fff';

    if (state.isSelected) {
      backgroundColor = COLORS.permaOceanGreen;
    } else if (state.isFocused) {
      backgroundColor = '#f1f3f4';
    }
    return {
      ...provided,
      backgroundColor,
      color: '#202124',
      '&:hover': {
        backgroundColor: '#f1f3f4',
      },
    };
  },

  singleValue: (provided) => {
    return {
      ...provided,
      fontSize: 14,
    };
  },

  input: (provided) => {
    return {
      ...provided,
      fontSize: 14,
    };
  },
};

interface Props {
  options: OptionsType<OptionType>;
  value: ValueType<OptionType, false>;
  onChange: (value: ValueType<OptionType, false>) => void;
  placeholder: string;
}

const Select: FC<Props> = ({ options, value, onChange, placeholder }) => (
  <ReactSelect
    options={options}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    styles={selectStyles}
  />
);

export default Select;
