/* eslint-disable import/prefer-default-export */
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import sessionStorage from '@/core/storage/sessionStorage';
import { formSchema } from '../util';

export const useQuickEligibilityForm = () => {
  const defaultFormValues = (() => {
    try {
      const basicInformation = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}');
      const { firstName, lastName, dateOfBirth, state, country } = basicInformation;
      const momentDoB = moment(dateOfBirth);
      const formattedDob = dateOfBirth && momentDoB.isValid() && momentDoB.format('MM/DD/YYYY');

      const memberDetails = JSON.parse(sessionStorage.getItem('TSQM_MemberDetails') || '{}');
      const { memberId: memberID } = memberDetails;

      return {
        memberID: memberID || '',
        firstName: firstName || '',
        lastName: lastName || '',
        state: state || '',
        country: country || '',
        dob: formattedDob || '',
      };
    } catch (err) {
      return {
        memberID: '',
        firstName: '',
        lastName: '',
        state: '',
        country: '',
        dob: '',
      };
    }
  })();

  return useForm({
    resolver: yupResolver(formSchema),
    context: {},
    defaultValues: defaultFormValues,
  });
};
