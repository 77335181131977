import { LDFlags } from 'ts-frontend/types';
import { AccountType, HomePageState } from '../Components/HomePage/types';
import { states } from '../Helpers/locales';
import getParamByName from '../utils/queryString';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
// eslint-disable-next-line import/no-cycle
import { getClientFlow, getClientFlowConfig } from './index';
// eslint-disable-next-line import/no-cycle
import { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
// eslint-disable-next-line import/no-cycle
import { FLOW_7_B2B_COPAY_GENERIC } from './Flow7';
import { EligibilityType, OneFormEligibilityStep, QMFlow, UpdateStepObj } from './types';
// eslint-disable-next-line import/no-cycle
import {
  allRegistrationPagesSteps,
  ineligiblePromo,
  initFlowConfig,
  registrationStepsValidation,
  skipIfExperiment,
  skipToStepIfAnswerValue,
  languageMatchingButtons,
  routeToLanguageMatchingIfExperiment,
  languageMatchingSkipIfNoLanguages,
  languageMatchingSelfServe,
  languageMatchingFilterHeading1,
  languageMatchingFilterHeading2,
  languageMatchingFilterButtons,
  routeToModalityIfOrgFlow,
} from './utils';
import { LANGUAGE_ID_ENGLISH } from '../Helpers/matchingLanguages';
import { shouldSkipOrRemovePreferredModality } from '../Helpers/preferredModalityHelper';
import { QM_COPY } from '../utils/qmCopy';

const OFFER_ID = 62;
const INITIAL_OFFER_STEP_ID = 24;

export const getRegistrationStepIDByFlowID = (
  state: HomePageState,
  answer?: UpdateStepObj
): number => {
  const { organizationFlowID, partnerFlowID } = state;
  let { qmPartnerCode, cpPartnerCode } = state;
  let accessCodeType = getParamByName('accessCodeType') as AccessCodeType | null;

  if (
    !(accessCodeType || qmPartnerCode || cpPartnerCode) &&
    (answer?.accessCodeType || answer?.accessCode)
  ) {
    accessCodeType = answer.accessCodeType || null;
    // Attempt to find the cpPartnerCode first
    if (accessCodeType && accessCodeType === 'cpPartnerCode') {
      cpPartnerCode = answer.accessCode || '';
    }
    // Default to qmPartnerCode
    if (!cpPartnerCode) {
      qmPartnerCode = answer.accessCode || '';
    }
  }
  const flowID = organizationFlowID || partnerFlowID;
  let flow = flowID && getClientFlow(flowID);
  if (cpPartnerCode || accessCodeType === 'cpPartnerCode') {
    flow = getClientFlow(FLOW_7_B2B_COPAY_GENERIC);
  }
  if (qmPartnerCode || accessCodeType === 'qmPartnerCode') {
    flow = getClientFlow(FLOW_138_B2B_VOUCHER_GENERIC_V2);
  }
  if (flow) {
    const registrationStepIndexes = flow.steps
      .map((step) => registrationStepsValidation.findIndex((validationFun) => validationFun(step)))
      .filter((index) => index > -1);
    if (registrationStepIndexes.length > 0) {
      return INITIAL_OFFER_STEP_ID + registrationStepIndexes[0];
    }
  }
  return INITIAL_OFFER_STEP_ID;
};

const skipModalitySelectionIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const allowedModalities = answer?.allowedModalities || state.allowedModalities || undefined;
    if (shouldSkipOrRemovePreferredModality({ ...state, allowedModalities })) {
      return skipToStepID || getRegistrationStepIDByFlowID(state, answer); // skip to registration if no modalities found.
    }
    return undefined;
  };

const skipReviewPlanIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const accountType = answer?.accountType || state.accountType;
    const manualFlowID = answer?.manualFlowID || state.manualFlowID;
    const isCouldNotBeVerified = answer?.isCouldNotBeVerified || state.isCouldNotBeVerified;

    const manualFlowConfig = manualFlowID ? getClientFlowConfig(manualFlowID) : undefined;
    const isManualFlow =
      manualFlowConfig?.eligibilityType === EligibilityType.manual ||
      getClientFlowConfig(state.organizationFlowID || state.partnerFlowID || state.redirectFrom)
        ?.eligibilityType === EligibilityType.manual;
    if (accountType && [AccountType.EAP, AccountType.BH].includes(accountType) && !isManualFlow) {
      return undefined;
    }
    if (isCouldNotBeVerified) {
      return undefined;
    }
    return skipModalitySelectionIfIrrelevant(skipToStepID)(state, answer) || 30; // go to modalities
  };

const showB2BForkFirst = (target: number) => (state: HomePageState) => {
  if (state.isReactivationFlow || state.isUpdateCoverageFlow || state.isMBHIneligibilityFlow) {
    return target;
  }
  return undefined;
};

const skipEligibilityForDTE =
  (b2bSkipTarget: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const { organizationFlowID } = answer || {};
    // If we successfully received a qmFlowID and the target flow has an eligibility type, show form
    if (
      organizationFlowID &&
      getClientFlowConfig(organizationFlowID)?.eligibilityType !== undefined
    ) {
      return undefined;
    }
    return showB2BForkFirst(b2bSkipTarget)(state) || 22;
  };

const ifOrgFlowRouteToModalityOrSkip = (
  state: HomePageState,
  answer: UpdateStepObj,
  flags?: LDFlags
) => {
  const nextStep = skipModalitySelectionIfIrrelevant()(state, answer);
  return flags ? routeToModalityIfOrgFlow(nextStep || 30)(state, answer, flags) : undefined;
};

// Omitting category for readability
const ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG: Omit<OneFormEligibilityStep, 'id' | 'category'> = {
  analyticsId: 54,
  response_category_id: 9,
  ...ineligiblePromo,
};

/** Service Specific Funnels: Therapy */
export const FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB = 200;

const flow: QMFlow = {
  flowId: FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'psychotherapy',
    isB2B: false,
    isTeen: true,
    shouldHideServiceSelection: true,
    version: 3,
  },
  steps: [
    {
      id: 1,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      response_category_id: 2,
      category: 'presentingProblems',
      internalTarget: 3,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      heading1: null,
      heading2: "To begin, tell us why you're looking for help today.",
      progressCurrent: 1,
      progressMax: 11,
      tip: 'findTheRightMatch',
      skipQuestionActions: [
        (state, answer, flags) => {
          if (!answer) {
            return skipIfExperiment({
              experimentName: 'experimentalCoverageVerification',
              variant: ['existing_checkout', 'no_max_cost', 'aggregate'],
              nextStepId: 100,
              shouldTrack: true,
            })(state, answer, flags);
          }
          return undefined;
        },
      ],
    },
    {
      id: 100,
      category: 'insuranceConfirmation',
      internalTarget: 4,
      flowId: FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB,
    },
    {
      id: 101,
      category: 'quickEligibility',
      inNetworkTarget: 102,
      timeoutTarget: 103,
    },
    {
      id: 102,
      category: 'quickEligibilityStatus',
      nextQuestionActions: [
        (state, answer, flags) => {
          switch (answer.quickStatusAction) {
            case 'continueWithInsurance': // Continue to B2C
            case 'continueWithoutInsurance':
              return 1;
            case 'resubmit': // Go back to previous screen (short eligibility form)
              return 101;
            case 'orgEap': // Go to QM questions for EAP / DTE flow
              return 1;
            case 'fileUpload':
              return 103;
            default:
              return undefined;
          }
        },
      ],
    },
    {
      id: 103,
      category: 'fileUpload',
      nextQuestionActions: [(state, answer, flags) => 104],
    },
    {
      id: 104,
      category: 'manualSubmit',
      nextQuestionActions: [(state, answer, flags) => 105],
    },
    {
      id: 105,
      category: 'requestReceived',
    },
    {
      id: 3,
      prompt: 'How would you rate your sleeping habits?',
      analyticsId: 59,
      response_category_id: 7,
      category: 'select1',
      heading2: 'How would you rate your sleeping habits?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Poor',
        },
      ],
      skipQuestionActions: [
        skipIfExperiment({
          experimentName: 'removeOnboardingQuestions',
          variant: 'treatment',
          nextStepId: 4,
          shouldTrack: true,
        }),
      ],
      progressCurrent: 2,
      progressMax: 11,
    },
    {
      id: 4,
      prompt: 'How would you rate your physical health?',
      analyticsId: 60,
      response_category_id: 7,
      category: 'select1',
      heading2: 'How would you rate your current physical health?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Poor',
        },
      ],
      skipQuestionActions: [
        skipIfExperiment({
          experimentName: 'removeOnboardingQuestions',
          variant: 'treatment',
          nextStepId: 11,
        }),
      ],
      progressCurrent: 3,
      progressMax: 11,
    },
    // matching questions
    {
      id: 11,
      prompt: 'My gender is',
      analyticsId: 62,
      response_category_id: 4,
      category: 'select1',
      heading2: 'What gender do you identify with?',
      buttons: [
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 1,
        },
        {
          text: 'Transgender female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 4,
        },
        {
          text: 'Transgender male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 3,
        },
        {
          text: 'Gender queer',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 5,
        },
        {
          text: 'Gender variant',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 6,
        },
        {
          text: 'Other',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 7,
        },
        {
          text: 'Non-binary',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 8,
        },
      ],
      skipText: 'Skip',
      skipExternalTarget: null,
      skipInternalTarget: 12,
      progressCurrent: 4,
      progressMax: 11,
    },
    {
      id: 12,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      response_category_id: 3,
      category: 'select1',
      heading2: 'What gender would you prefer in a provider?',
      selfServe: {
        field: 'therapistGender',
      },
      buttons: [
        {
          text: 'No preference',
          externalTarget: null,
          internalTarget: 13,
          answerValue: undefined,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 1,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 13,
          answerValue: undefined,
        },
      ],
      progressCurrent: 5,
      progressMax: 11,
      nextQuestionActions: [
        (state, _answer, flags) => {
          if (
            flags?.experimentalCoverageVerification?.experimentActive &&
            flags.experimentalCoverageVerification.variant !== 'control' &&
            state.clientDateOfBirth
          ) {
            return 14;
          }
          return undefined;
        },
      ],
    },
    {
      id: 13,
      prompt: 'What is your age?',
      analyticsId: 61,
      response_category_id: 5,
      category: 'dateOfBirth',
      heading2: "What's your date of birth?",
      heading3: 'As a healthcare provider, we must ask this.',
      targets: {
        adult: 14,
        teen: 21,
        under13: 'https://helpnow.talkspace.com/under-13',
      },
      progressCurrent: 6,
      progressMax: 11,
    },
    {
      id: 14,
      prompt: QM_COPY.state.prompt,
      analyticsId: 63,
      response_category_id: 6,
      category: 'dropdown',
      heading2: QM_COPY.state.title,
      heading3: QM_COPY.state.subtitle,
      selectPlaceholder: QM_COPY.state.placeholder,
      selectOptions: states,
      buttonText: QM_COPY.state.continue,
      buttonTarget: 18,
      skipExternalTarget: null,
      progressCurrent: 8,
      progressMax: 11,
      nextQuestionActions: [showB2BForkFirst(15)],
    },
    {
      id: 15,
      category: 'b2bFork',
      buttonTarget: 22,
      dispatcherStepTarget: 16,
      ctSplitTarget: 23,
      nextQuestionActions: [
        (state, answer, flags) => {
          if (
            flags?.experimentalCoverageVerification?.experimentActive &&
            flags.experimentalCoverageVerification.variant !== 'control'
          ) {
            if (answer?.isB2BOutOfNetwork) {
              // go to original OneForm, will show only fields for OON
              return 22;
            }
            if (answer?.b2bForkResult === 'b2c') {
              // go to QM steps
              return 1;
            }
            // go to short eligibility form
            return 101;
          }
          return showB2BForkFirst(18)(state);
        },
      ],
    },
    {
      id: 16,
      category: 'dispatcherInQM',
      initialDispatcherStep: 'organizationName',
      internalTarget: 17,
      progressCurrent: 10,
      progressMax: 11,
      nextQuestionActions: [skipEligibilityForDTE(18)],
    },
    {
      id: 17,
      category: 'oneFormEligibility', // EAP
      progressCurrent: 11,
      progressMax: 11,
      buttonTarget: 22,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
      nextQuestionActions: [showB2BForkFirst(18)],
    },
    {
      id: 18,
      category: 'currentProvider',
      buttonTarget: 19,
      currentProviderTarget: 15,
      nextQuestionActions: {
        15: [showB2BForkFirst(22)],
        19: [routeToLanguageMatchingIfExperiment(190, 19)],
      },
    },
    {
      id: 190,
      prompt: 'What’s your preferred language?',
      analyticsId: 140,
      response_category_id: 7,
      category: 'selectDynamic',
      dataDependencies: [{ key: 'matchingLanguages', options: { forceRefetch: true } }],
      heading1: 'What’s your preferred language?',
      heading2: 'Based on the state you selected, there are providers who speak these languages:',
      buttons: languageMatchingButtons(191),
      skipQuestionActions: [languageMatchingSkipIfNoLanguages(19)],
      nextQuestionActions: {
        19: [ifOrgFlowRouteToModalityOrSkip],
        191: [
          (state, answer, flags) => {
            if (answer.response_value === LANGUAGE_ID_ENGLISH) {
              return ifOrgFlowRouteToModalityOrSkip(state, answer, flags);
            }
            return undefined;
          },
        ],
      },
      progressCurrent: 9,
      progressMax: 11,
    },
    {
      id: 191,
      // This prompt does not show to users, but is used in analytics, the "[language]" part is intentional
      prompt: 'Are you willing to wait for a [language]-speaking provider?',
      analyticsId: 141,
      response_category_id: 7,
      category: 'selectDynamic',
      selfServe: languageMatchingSelfServe(190, 191),
      heading1: languageMatchingFilterHeading1(190),
      heading2: languageMatchingFilterHeading2(190),
      buttons: languageMatchingFilterButtons(19),
      skipQuestionActions: [skipToStepIfAnswerValue(19, `${LANGUAGE_ID_ENGLISH}`)],
      nextQuestionActions: [ifOrgFlowRouteToModalityOrSkip],
      progressCurrent: 10,
      progressMax: 11,
    },
    {
      id: 19,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 15,
      zeroMatchTarget: 15,
      matchOverlay: true,
      matchOverlayCampaignType: 'quickmatch90_1',
      canMatchOverlayBeSkipped: true,
      showCustomerRegistrationModalWithoutPayment: true,
      nextQuestionActions: [
        (state, answer, flags) => {
          if (
            flags?.experimentalCoverageVerification?.experimentActive &&
            flags.experimentalCoverageVerification.variant !== 'control'
          ) {
            const orgEapStepID = 16;

            const insuranceConfirmationAnswer = state.stepAnswers.find(
              (stepAnswer) =>
                Number(stepAnswer.flowId) === Number(state.flowId) &&
                Number(stepAnswer.stepId) === 100
            );

            const quickStatusActionAnswer = state.stepAnswers.find(
              (stepAnswer) =>
                Number(stepAnswer.flowId) === Number(state.flowId) &&
                Number(stepAnswer.stepId) === 102
            );

            /*
            If:
              - The user is in BH flow
              - The user's insurance is in network
              - The user's insurance is verified
            Then:
              - Send them to oneFormEligibility (22)
            */

            if (
              insuranceConfirmationAnswer?.answerValue?.insuranceConfirmation === 'check' &&
              state.quickEligibilityInfo?.insuranceEligibility?.isEligible &&
              state.quickEligibilityInfo?.insuranceEligibility?.verificationSucceeded
            ) {
              return 22;
            }

            if (quickStatusActionAnswer?.answerValue?.quickStatusAction === 'orgEap') {
              return orgEapStepID;
            }

            switch (insuranceConfirmationAnswer?.answerValue?.insuranceConfirmation) {
              case 'direct':
                return INITIAL_OFFER_STEP_ID;
              case 'orgEap':
                return orgEapStepID;
              case 'check':
                return INITIAL_OFFER_STEP_ID;
              default:
                // Do nothing
                break;
            }
          }
          return undefined;
        },
        showB2BForkFirst(22),
      ],
    },
    {
      id: 20,
      category: 'matchConfirmation',
      buttonTarget: 22,
    },
    // teen consent (age 13 - 18)
    {
      id: 21,
      prompt: 'Consent',
      analyticsId: 562,
      response_category_id: 7, // TODO
      heading2:
        'Based on your age and state, parent or guardian consent may be needed. Does this work for you?',
      category: 'select1',
      buttons: [
        {
          text: 'Yes',
          answerValue: 'Yes',
          externalTarget: null,
          internalTarget: 14,
        },
        {
          text: 'No, show me other resources',
          answerValue: 'No, show me other resources',
          externalTarget: 'https://helpnow.talkspace.com/under-18-no-consent',
          internalTarget: null,
        },
      ],
      progressCurrent: 7,
      progressMax: 11,
    },
    // 21 is Already occupied by teen consent
    {
      id: 22,
      category: 'oneFormEligibility', // BH
      buttonTarget: 32,
      noCoverageButtonTarget: INITIAL_OFFER_STEP_ID,
      nextQuestionActions: [
        (state, answer, flags) => {
          if (
            flags?.experimentalCoverageVerification?.experimentActive &&
            flags.experimentalCoverageVerification.variant !== 'control'
          ) {
            const insuranceConfirmationAnswer = state.stepAnswers.find(
              (stepAnswer) =>
                Number(stepAnswer.flowId) === Number(state.flowId) &&
                Number(stepAnswer.stepId) === 100
            );

            if (
              insuranceConfirmationAnswer?.answerValue?.insuranceConfirmation === 'check' &&
              state.quickEligibilityInfo?.insuranceEligibility?.isEligible &&
              state.quickEligibilityInfo?.insuranceEligibility?.verificationSucceeded
            ) {
              return 30;
            }

            if (state.b2bForkResult === 'b2b' && state.isB2BOutOfNetwork) {
              // for OON after filling this form, navigate to QM questions
              return 1;
            }
          }

          if (flags?.languageMatchingDefault && state.accountType === 'dte') {
            // if language matching, and language not selected, loop back to language steps
            const languageAnswer = state.stepAnswers.find(
              (stepAnswer) =>
                Number(stepAnswer.flowId) === Number(state.flowId) &&
                Number(stepAnswer.stepId) === 190
            );
            const { matchingLanguages } = state;
            if (matchingLanguages === null && !languageAnswer) {
              return 190;
            }
          }

          return undefined;
        },
        skipReviewPlanIfIrrelevant(),
      ],
      disableOrganization: true,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
    },
    { id: 23, category: 'registerWithoutPayment' },
    {
      id: 30,
      category: 'sessionModality',
      buttonTarget: INITIAL_OFFER_STEP_ID,
      nextQuestionActions: [getRegistrationStepIDByFlowID],
    },
    {
      id: 32,
      category: 'reviewPlan',
      buttonTarget: 30,
      nextQuestionActions: [
        (state, answer, flags) => {
          if (flags?.languageMatchingDefault) {
            // if language matching, and language not selected, loop back to language steps
            const languageAnswer = state.stepAnswers.find(
              (stepAnswer) =>
                Number(stepAnswer.flowId) === Number(state.flowId) &&
                Number(stepAnswer.stepId) === 190
            );
            const { matchingLanguages } = state;
            if (matchingLanguages === null && !languageAnswer) {
              return 190;
            }
          }
          return undefined;
        },
        skipModalitySelectionIfIrrelevant(),
      ],
    },
    ...allRegistrationPagesSteps({
      initialStepID: INITIAL_OFFER_STEP_ID,
      offersPageSettings: {
        offerID: OFFER_ID,
        previousStepsUntilMatches: 2,
      },
    }),
  ],
};

export default flow;
