import { typeNames } from './ReactFrameTypes';
import localStorage from '../../core/storage/localStorage';

let localMemParentOrigin: string | null = null;

export const getParentOrigin = () => {
  const { hash } = window.location;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hashParams: any = {};

  hash.replace(/[#&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    hashParams[key] = value;
    return '';
  });
  const memoryParentOrigin = localStorage.getItem('parentOrigin');

  const parentOrigin = decodeURIComponent(
    hashParams.origin || localMemParentOrigin || memoryParentOrigin
  );

  if (parentOrigin && parentOrigin !== 'null') {
    localMemParentOrigin = parentOrigin;
    localStorage.setItem('parentOrigin', parentOrigin);
    return parentOrigin;
  }
  return null;
};

const isMobileParentOrigin = () => getParentOrigin() === 'mobileClient';

export const isiOS = (type: typeNames = 'finishLoading') =>
  (window as any).webkit &&
  (window as any).webkit.messageHandlers &&
  typeof (window as any).webkit.messageHandlers[type] === 'object' &&
  isMobileParentOrigin();

export const isAndroid = () => !!(window as any).toAndroidDevice && isMobileParentOrigin();

export const isMobile = () => isiOS() || isAndroid();
