import React, { ChangeEvent } from 'react';
import {
  Button,
  Col,
  getSpacing,
  Grid,
  Row,
  styled,
  TextAreaRounded,
  View,
} from '@talkspace/react-toolkit';
import { states, gender as genderConfigs } from '@talkspace/configs';

import { useDirectoryActions, useDirectoryState } from '@/directory/DirectoryContext';
import useQueryTherapists from '@/directory/useQueryTherapists';
import { SelectOption } from '@/directory/types';
import TextInput from '@/directory/TextInput';
import Select from '@/directory/Select';
import useQueryCacheCheck from '@/directory/useQueryCacheCheck';
import { therapistsQueryKey } from '@/directory/queryKeys';

const MainView = styled(View)({
  gap: getSpacing('space300'),
  alignItems: 'center',
});

const communicationPreferencesOptions = [
  { value: 'Direct and straightforward', label: 'Direct and straightforward' },
  { value: 'Gentle and exploratory', label: 'Gentle and exploratory' },
  { value: 'Balanced', label: 'Balanced' },
];

const conversationalDynamicsOptions = [
  { value: 'I prefer to lead the conversation', label: 'I prefer to lead the conversation' },
  {
    value: 'I prefer the therapist to guide the discussion',
    label: 'I prefer the therapist to guide the discussion',
  },
  { value: 'A mix of both', label: 'A mix of both' },
];

const genderOptions = Object.entries(genderConfigs).map(([value, label]) => {
  return { value, label };
});

const specialtyOptions = [
  {
    value: 'anxiety',
    label: 'Anxiety',
  },
  {
    value: 'depression',
    label: 'Depression',
  },
  {
    value: 'stress',
    label: 'Stress',
  },
  {
    value: 'relationships',
    label: 'Relationships',
  },
  {
    value: 'couple',
    label: 'Couple',
  },
  {
    value: 'marital',
    label: 'Marital',
  },
  {
    value: 'teen',
    label: 'Teen',
  },
  {
    value: 'addiction',
    label: 'Addiction',
  },
  {
    value: 'insomnia',
    label: 'Insomnia',
  },
];

const stateOptions = Object.entries(states)
  .map(([value, label]) => {
    return { value, label };
  })
  .filter(({ value }) => value !== 'US');

const payerOptions = [
  { label: 'Aetna', value: 'Aetna' },
  { label: 'Premera', value: 'Premera' },
];

const SearchBar = () => {
  const { searchParams, isSearchValid, mode } = useDirectoryState();
  const { setSearchParams, clearSearchParams } = useDirectoryActions();

  const { refetch, isLoading } = useQueryTherapists(searchParams, false);

  const cacheExists = useQueryCacheCheck(therapistsQueryKey(searchParams));

  const handleStateChange = (option: SelectOption) => {
    setSearchParams({ state: option });
  };

  const handleSpecialtyChange = (option: SelectOption) => {
    setSearchParams({ specialty: option });
  };

  const handlePayerChange = (option: SelectOption) => {
    setSearchParams({ payer: option });
  };

  const handleGenderChange = (option: SelectOption) => {
    setSearchParams({ gender: option });
  };

  const handleCommunicationPreferenceChange = (option: SelectOption) => {
    setSearchParams({ communicationPreferences: option });
  };

  const handleConversationalDynamicChange = (option: SelectOption) => {
    setSearchParams({ conversationalDynamics: option });
  };

  const handleClearPress = () => {
    clearSearchParams();
  };

  const handleWhyAreYouHereChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ whyAreYouHere: e.target.value });
  };

  const handleAgeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);

    if (Number.isNaN(newValue)) {
      return;
    }

    setSearchParams({ age: newValue });
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ name: e.target.value });
  };

  const {
    name,
    gender,
    communicationPreferences,
    conversationalDynamics,
    whyAreYouHere,
    aiPrompt,
    age,
  } = searchParams;

  const handleFindCarePress = async () => {
    await refetch();
  };

  const { state, specialty, payer } = searchParams;

  return (
    <MainView>
      <Grid rowSpacing="space200">
        <Row>
          <Col xs={4} sm={3} md={2} lg={2}>
            <TextInput value={name} onChange={handleNameChange} placeholder="What's your name?" />
          </Col>

          <Col xs={4} sm={3} md={2} lg={2}>
            <TextInput
              value={whyAreYouHere}
              onChange={handleWhyAreYouHereChange}
              placeholder="Why are you here?"
            />
          </Col>

          <Col xs={4} sm={3} md={2} lg={2}>
            <TextInput
              value={age === 0 ? '' : age.toString()}
              onChange={handleAgeChange}
              placeholder="What is your age?"
            />
          </Col>

          <Col xs={4} sm={3} md={2} lg={2}>
            <Select
              options={stateOptions}
              value={state}
              onChange={handleStateChange}
              placeholder="Location"
            />
          </Col>

          <Col xs={4} sm={3} md={2} lg={2}>
            <Select
              options={specialtyOptions}
              onChange={handleSpecialtyChange}
              value={specialty}
              placeholder="Specialty"
            />
          </Col>

          <Col xs={4} sm={3} md={2} lg={2}>
            <Select
              options={payerOptions}
              onChange={handlePayerChange}
              value={payer}
              placeholder="Insurance"
            />
          </Col>

          <Col xs={4} sm={3} md={2} lg={2}>
            <Select
              options={genderOptions}
              onChange={handleGenderChange}
              value={gender}
              placeholder="Your gender"
            />
          </Col>

          <Col xs={4} sm={3} md={3} lg={3}>
            <Select
              options={communicationPreferencesOptions}
              onChange={handleCommunicationPreferenceChange}
              value={communicationPreferences}
              placeholder="Communication preference"
            />
          </Col>

          <Col xs={4} sm={3} md={3} lg={3}>
            <Select
              options={conversationalDynamicsOptions}
              onChange={handleConversationalDynamicChange}
              value={conversationalDynamics}
              placeholder="Conversational dynamics"
            />
          </Col>

          {mode === 'dev' && (
            <Col xs={4} sm={4} md={6} lg={6}>
              <TextAreaRounded
                numberOfLines={20}
                style={{
                  width: '200%',
                  height: 400,
                  minHeight: 102,
                }}
                value={
                  aiPrompt ||
                  `Attached below is a json describing each therapist using array of therapists in which each object is a therapist
                  client information using the property clientInfo
                  For each therapist in the array of therapists, rewrite the dynamicBio property of the therapist object based on the clientInfo add the whyAreYouHere and the clientName to relate the bio to the reason the client is here
                  This is used in a therapist directory in order for the client to select the therapist.
                  Format of the therapist’s dynamicBio as html mark down in a <span> element inside a json with and array property therapists that contains the dynamicBio property as follows:
                  “{ “therapists”: “[”{“id”: // therapist id, “dynamicBio”: HTML }“]” }”
                  Bold the most relevant areas to the clientInfo’s whyAreYouHere property of the html format.
                  At the bottom after an extra blank line
                  inside the <span> element, using <ul> and <li> items,
                  summarize the dynamicBio in 3 short bullet points, bolding specific phrases that are extra important.
                  Always return html`
                }
                onChangeText={(value) => setSearchParams({ aiPrompt: value })}
                multiline
                placeholder="AI prompt"
              />
            </Col>
          )}
        </Row>
      </Grid>

      <View row align="center" style={{ gap: getSpacing('space100') }}>
        <Button
          text="Find care"
          disabled={!isSearchValid || isLoading || cacheExists}
          onPress={handleFindCarePress}
          isLoading={isLoading}
        />

        <Button text="Clear all" variant="secondary" onPress={handleClearPress} />
      </View>
    </MainView>
  );
};

export default SearchBar;
