import { InsuranceEligibilityPayer, ServiceType } from 'ts-frontend/types';
import {
  FlowConfig,
  InternalTarget,
  OneFormEligibilityStep,
  RecoveredFields,
  UpdateStep,
} from '@/Flows/types';
import { B2BForkResult, HomePageState } from '@/Components/HomePage/types';

// TODO:  The types below are not used. The API call uses the same payload.
//        leaving it here just for visibility of business logic that Eric gathered.
//        There is a unified type inside OneFormEligibilityHelpers.ts that has all these fields
//        but has them as optionals instead of using Union types. The issue is that there is not
//        enough information to discern when to use which type.

// fields that are required for all flows
export interface BaseEligibilityMetaData {
  firstName: string;
  lastName: string;
  // should be of the format moment(dateOfBirth).format('YYYY-MM-DD)- how can we specify this?
  dateOfBirth: string;
  // can we specify a phone format in this interface?
  phone: string;
  employeeRelationship: string;
  heardAbout: string;
  email: string;
}

// We might be able to call this DTEMetaData
export interface OrganizationMetaData extends BaseEligibilityMetaData {
  employeeId?: string;
  authorizationCode?: string;
  numberOfSessions?: string;
}

// flow 19, flow 44 (according to Asaf we only need to collect address for EAP flows)
export interface OrganizationWithAddressMetaData extends BaseEligibilityMetaData {
  address: string;
  addressStreet: string;
  city: string;
  address2: string;
  state: string;
  zipcode: string;
  country: string;
}

// Trizetto flows
export interface BHEligibilityMetaData extends OrganizationWithAddressMetaData {
  memberId: number;
}

// manual and file-based verification flows
export interface ManualAndFileBasedBHMetaData extends BHEligibilityMetaData {
  voucher: string;
  groupId?: string;
}

export interface KeywordRequestParams {
  // keyword is a formatted organization name for flows in which Talkspace is provided by an employer.
  keyword: string;
  email: string;
  flowId: string;
  metadata:
    | BaseEligibilityMetaData
    | BHEligibilityMetaData
    | OrganizationWithAddressMetaData
    | OrganizationMetaData;
  accessCode?: string;
}

export type EligibilityField =
  | 'insuranceDetails'
  | 'clientInfo'
  | 'clientAddress'
  | 'organizationDetails'
  | 'email';

export interface BaseCheckEligibilityArgs {
  flowId: number;
  email: string;
  metadata: ManualAndFileBasedBHMetaData;
  // flowConfig: FlowConfig;
  // location: RouteComponentProps['location'];
  // history: RouteComponentProps['history'];
  // passed down as prop from StepLayout component
  // dispatcherKeyword: string | null;
  // updateStep: (target: InternalTarget, updateStepObj: UpdateStepObj) => void;
  serviceType?: { key: string; value: string; label: string; isDisabled?: boolean };
  buttonTarget?: InternalTarget;
}
export interface CheckManualCoverageArgs extends BaseCheckEligibilityArgs {
  accessCode: string;
}

export interface CheckFileBasedEligibilityArgs extends BaseCheckEligibilityArgs {
  serviceType: { key: string; value: string; label: string; isDisabled?: boolean };
}

interface SelectField<T = string> {
  value: T;
  label: string;
}

interface SelectFieldWithKey extends SelectField {
  key: string;
}

export enum OneFormEligibilityFieldNames {
  memberID = 'memberID',
  groupID = 'groupID',
  firstName = 'firstName',
  lastName = 'lastName',
  dateOfBirth = 'dateOfBirth',
  phone = 'phone',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  zipcode = 'zipcode',
  country = 'country',
  clientState = 'clientState',
  employeeRelation = 'employeeRelation',
  employeeFirstName = 'employeeFirstName',
  employeeLastName = 'employeeLastName',
  employeeSameAddressCheckbox = 'employeeSameAddressCheckbox',
  employeeAddressLine1 = 'employeeAddressLine1',
  employeeAddressLine2 = 'employeeAddressLine2',
  employeeCity = 'employeeCity',
  employeeState = 'employeeState',
  employeeZipcode = 'employeeZipcode',
  employeeCountry = 'employeeCountry',
  organizationName = 'organizationName',
  heardAbout = 'heardAbout',
  email = 'email',
  employeeID = 'employeeID',
  authorizationCode = 'authorizationCode',
  authorizationCodeExpiration = 'authorizationCodeExpiration',
  numberOfSessions = 'numberOfSessions',
  serviceType = 'serviceType',
  verificationCheckbox = 'verificationCheckbox',
  attendedSchool = 'attendedSchool',
  marketingConsent = 'marketingConsent',
  socialSecurity = 'socialSecurity',
  secondaryMemberID = 'secondaryMemberID',
  secondaryPayerID = 'secondaryPayerID',
}
export interface OneFormEligibilityFields {
  [OneFormEligibilityFieldNames.memberID]: string | undefined;
  [OneFormEligibilityFieldNames.groupID]: string | undefined;
  [OneFormEligibilityFieldNames.firstName]: string;
  [OneFormEligibilityFieldNames.lastName]: string;
  [OneFormEligibilityFieldNames.dateOfBirth]: string;
  [OneFormEligibilityFieldNames.phone]: string | undefined;
  [OneFormEligibilityFieldNames.addressLine1]: string | undefined;
  [OneFormEligibilityFieldNames.addressLine2]: string | undefined;
  [OneFormEligibilityFieldNames.city]: string | undefined;
  [OneFormEligibilityFieldNames.zipcode]: string | undefined;
  [OneFormEligibilityFieldNames.country]: string | undefined;
  /**
   * Dropdown alternative to state
   */
  [OneFormEligibilityFieldNames.clientState]: string | undefined;
  [OneFormEligibilityFieldNames.employeeRelation]: SelectFieldWithKey | null;
  [OneFormEligibilityFieldNames.employeeFirstName]: string | undefined;
  [OneFormEligibilityFieldNames.employeeLastName]: string | undefined;
  [OneFormEligibilityFieldNames.employeeSameAddressCheckbox]: boolean | undefined;
  [OneFormEligibilityFieldNames.employeeAddressLine1]: string | undefined;
  [OneFormEligibilityFieldNames.employeeAddressLine2]: string | undefined;
  [OneFormEligibilityFieldNames.employeeCity]: string | undefined;
  [OneFormEligibilityFieldNames.employeeState]: string | undefined;
  [OneFormEligibilityFieldNames.employeeZipcode]: string | undefined;
  [OneFormEligibilityFieldNames.employeeCountry]: string | undefined;
  [OneFormEligibilityFieldNames.organizationName]: string | undefined;
  [OneFormEligibilityFieldNames.heardAbout]: SelectFieldWithKey | null;
  [OneFormEligibilityFieldNames.email]: string | undefined;
  [OneFormEligibilityFieldNames.employeeID]: string | undefined;
  [OneFormEligibilityFieldNames.authorizationCode]: string | undefined;
  [OneFormEligibilityFieldNames.authorizationCodeExpiration]: string | undefined;
  [OneFormEligibilityFieldNames.numberOfSessions]: SelectField | null | undefined;
  [OneFormEligibilityFieldNames.serviceType]: SelectField<ServiceType> | null | undefined;
  [OneFormEligibilityFieldNames.verificationCheckbox]: boolean | undefined;
  [OneFormEligibilityFieldNames.attendedSchool]: SelectField<number | string> | null | undefined;
  [OneFormEligibilityFieldNames.marketingConsent]: boolean | undefined;
  [OneFormEligibilityFieldNames.socialSecurity]: string | null | undefined;
  [OneFormEligibilityFieldNames.secondaryMemberID]: string | null | undefined;
  [OneFormEligibilityFieldNames.secondaryPayerID]: string | null | undefined;
}

export interface OneFormEligibilityYupContext {
  hasEmployeeId?: boolean;
  hasAuthCode?: boolean;
  authCodeMaxLength?: number;
  hasNumberOfSessions?: boolean;
  authCodeExpirationEnabled?: boolean;
  authCodeExpirationMaxDays?: number;
  flowId?: number;
  optionalOrganization?: boolean;
  isGroupIdRequired?: boolean;
  collectInsuranceDetails?: boolean;
  isAddressRequired?: boolean;
  hasServiceType?: boolean;
  specificCountryCodesToSupport?: string[];
  removePhoneNumber?: boolean;
  removeEmail?: boolean;
  removeEmployeeRelation?: boolean;
  removeReferralSource?: boolean;
  removeAddress?: boolean;
  displayEmployeeInfo?: boolean;
  employeeSameAddress?: boolean;
  onlyFirstName?: boolean;
  optionalTruthCheckbox?: boolean;
  addressLine1ErrorMessage?: string;
  onlyTeens?: boolean;
  collectTeenSchool?: boolean;
  showMarketingConsent?: boolean;
  socialSecurityNumberEnabled?: boolean;
}

export type RecoveredField = keyof RecoveredFields;

export interface OneFormEligibilityProps {
  flowConfig: FlowConfig;
  flowId: number;
  step: OneFormEligibilityStep;
  service: HomePageState['service'];
  updateStep: UpdateStep;
  isCreateRoomFlow: boolean;
  isReactivationFlow: boolean;
  isUpdateCoverageFlow: boolean;
  isMBHIneligibilityFlow: boolean;
  setRecoveredField: <T extends RecoveredField>(field: T, data: RecoveredFields[T]) => void;
  payer?: InsuranceEligibilityPayer | null;
  overrideFlowID: number | null;
  organizationFlowID: number | undefined;
  b2bForkResult?: B2BForkResult;
  partnerFlowID: number | undefined;
  isB2BOutOfNetwork: boolean | undefined;
  isCouldNotBeVerified: boolean | undefined;
  scrollPage: (scrollParams: { top: number; behavior?: ScrollBehavior }) => void;
  setClientDateOfBirth: (clientAge: string) => void;
  setBlurParentContent: (bool: boolean) => void;
  setReferralSource: (referralSource: string) => void;
  setIsCouldNotBeVerified: (isNotVerified: boolean) => void;
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
}
