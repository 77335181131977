import { ChangeEvent, FC } from 'react';
import { COLORS, styled } from '@talkspace/react-toolkit';

const StyledInput = styled.input`
  width: 100%;
  padding: 24px;
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  font-size: 14px;
  color: #202124;
  outline: none;
  transition: border-color 0.3s;
  height: 40px;

  &:focus {
    border-color: ${COLORS.permaOceanGreen};
  }

  &:hover {
    border-color: #c6c6c6;
  }

  &::placeholder {
    color: #80868b;
  }
`;

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const TextInput: FC<Props> = ({ value, onChange, placeholder }) => (
  <StyledInput type="text" value={value} onChange={onChange} placeholder={placeholder} />
);

export default TextInput;
