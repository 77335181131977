import { useMutation, useQueryClient, UseMutationResult } from 'react-query';

type MutationFunction<TData, TVariables> = (variables: TVariables) => Promise<TData>;
type KeyGenerator<TVariables> = (variables: TVariables) => string | string[];

const useMutationWithCache = <TData, TVariables>(
  mutationFn: MutationFunction<TData, TVariables>,
  getKey: KeyGenerator<TVariables>
): UseMutationResult<TData, Error, TVariables, unknown> => {
  const queryClient = useQueryClient();

  return useMutation(async (variables: TVariables) => {
    const key = getKey(variables);

    const cachedData = queryClient.getQueryData<TData>(key);
    if (cachedData) {
      return cachedData;
    }

    const data = await mutationFn(variables);

    queryClient.setQueryData(key, data);

    return data;
  });
};

export default useMutationWithCache;
