import { FunctionComponent } from 'react';
import { ServiceType } from 'ts-frontend/types';
import BaseReviewPlan from './BaseReviewPlan';

import { ReviewPlanStep, UpdateStep } from '@/Flows/types';
import {
  PLAN_REVIEW_BH,
  PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE,
  PLAN_REVIEW_EAP_MESSAGE,
  PLAN_REVIEW_EAP_VIDEO,
} from '@/utils/reviewPlan';

interface BHReviewPlanProps {
  step: ReviewPlanStep;
  reviewPlanDetails?:
    | typeof PLAN_REVIEW_BH
    | typeof PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE
    | typeof PLAN_REVIEW_EAP_MESSAGE
    | typeof PLAN_REVIEW_EAP_VIDEO;
  updateStep: UpdateStep;
  redirectFrom: number;
  organizationFlowID?: number;
  partnerFlowID?: number;
  service: ServiceType | null;
  copayCents?: string;
  totalSessions: number;
}

const ReviewPlansBHOrEAP: FunctionComponent<BHReviewPlanProps> = ({
  step,
  reviewPlanDetails,
  updateStep,
  redirectFrom,
  organizationFlowID,
  partnerFlowID,
  service,
  copayCents,
  totalSessions,
}) => {
  const finalFlowID = Number(redirectFrom || organizationFlowID || partnerFlowID);
  const copayCentsAsNumber = Number(copayCents);
  return (
    <BaseReviewPlan
      planDetails={reviewPlanDetails}
      updateStep={updateStep}
      step={step}
      finalFlowID={finalFlowID}
      copayCents={copayCentsAsNumber}
      totalSessions={totalSessions}
      service={service}
    />
  );
};

export default ReviewPlansBHOrEAP;
