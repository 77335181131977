import { Spinner } from '@talkspace/react-toolkit';

import TherapistCard from '@/directory/TherapistCard';
import useQueryTherapists from '@/directory/useQueryTherapists';
import { useDirectoryState } from '@/directory/DirectoryContext';

const TherapistList = () => {
  const { searchParams } = useDirectoryState();

  const { data, isLoading } = useQueryTherapists(searchParams, false);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {(data || []).map((therapist) => (
        <TherapistCard key={therapist.id} therapist={therapist} />
      ))}
    </>
  );
};

export default TherapistList;
